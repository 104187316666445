import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store/reducers";
import { backSteep } from "../../store/actions/fichaMedicaActions";
import Api from "../../utils/api";
import { useParams } from "react-router-dom";
import Button from "../Button";
import IFichaCargaMedica from "../../interfaces/IFichaMedicaCarga";
import { setDatos } from "../../store/actions/fichaMedicaActions";
import OBJtoXML from "../../utils/objToXML";
import formToObj from "../../utils/formToObj";

export default function HistoriaClinica() {
  const form = useRef(null);
  const fichaMedica = useSelector((state: RootState) => state.fichaMedica);

  const [apiMessage, setApiMessage] = useState("");
  const [isFetching, setIsFetching] = useState(false);

  const { id = "-1" } = useParams();

  //const objSolicitud = useSelector((state: RootState) => state.ObjSolicitud);

  const dispatch = useDispatch();

  useEffect(() => {
  }, []);

  function handleClickVolver() {
    dispatch(backSteep());
  }

  function handleClickValidar() {
    setApiMessage("");
    setIsFetching(true);

    if(!form.current){
      return;
    }
    const body = formToObj(form.current);

    let obj: IFichaCargaMedica = fichaMedica;
    obj = {
      ...obj,
      campos: {
        ...obj.campos,
        ...body,
      },
      steep: 5,
    };
    Api("consumir").post({
      servicio: "fichaMedicaSet",
      param: {
        pc_request: OBJtoXML({ FICHA_MEDICA: obj.campos }),
        pco_pasajero: id,
        pn_pagina: 5,
      }
    }).then(res => {
      setIsFetching(false);
      if (res.mensaje.tipo === "E") {
        setApiMessage(res.mensaje.descripcion);
        return;
      }
      dispatch(setDatos(obj));
    })
  }

  return (
    <form ref={form} className="container mb-5">
      <h3 className="color-platform text-center">Historia clínica</h3>
      <div className="row">
        <div className="col-lg-6 col-12 my-2">
          <span>Grupo y Factor Sanguíneo:</span>
          <input name="D_GRUPO_SANGUINEO" className="form-control" type="text" />
        </div>
        <div className="col-12"></div>
        <div className="col-lg-6 col-12 my-2">
          <span>¿Se encuentra actualmente bajo tratamiento médico?:</span>
          <select className="form-select" name="M_TRATAMIENTO_MEDICO">
            <option value=""></option>
            <option value="S">Si</option>
            <option value="N">No</option>
          </select>
        </div>
        <div className="col-lg-6 col-12 my-2">
          <span>¿Por qué enfermedad?:</span>
          <input name="D_ENFERMEDAD" className="form-control" type="text" />
        </div>

        <div className="col-lg-6 col-12 my-2">
          <span>¿Está tomando algún medicamento?:</span>
          <select className="form-select" name="M_MEDICAMENTO">
            <option value=""></option>
            <option value="S">Si</option>
            <option value="N">No</option>
          </select>
        </div>
        <div className="col-lg-6 col-12 my-2">
          <span>¿Cuál?:</span>
          <input name="D_MEDICAMENTO" className="form-control" type="text" />
        </div>

        <div className="col-12 my-2">
          <span>¿Cuál es la dosis y la frecuencia diaria?:</span>
          <input name="D_DOSIS_FRECUENCIA" className="form-control" type="text" />
        </div>

        <div className="col-lg-6 col-12 my-2">
          <span>¿Tuvo algún accidente o internación en los últimos 12 meses?:</span>
          <select className="form-select" name="M_INTERNACION">
            <option value=""></option>
            <option value="S">Si</option>
            <option value="N">No</option>
          </select>
        </div>
        <div className="col-lg-6 col-12 my-2">
          <span>¿Por qué motivo? (Diagnóstico):</span>
          <input name="D_INTERNACION" className="form-control" type="text" />
        </div>
        <div className="col-lg-6 col-12 my-2">
          <span>¿Fué sometido a alguna cirugía?:</span>
          <select className="form-select" name="M_CIRUGIA">
            <option value=""></option>
            <option value="S">Si</option>
            <option value="N">No</option>
          </select>
        </div>
        <div className="col-lg-6 col-12 my-2">
          <span>¿De qué tipo?:</span>
          <input name="D_CIRUGIA" className="form-control" type="text" />
        </div>


        <div className="col-lg-6 col-12 my-2">
          <span>¿Es alérgico?:</span>
          <select className="form-select" name="M_ALERGICO">
            <option value=""></option>
            <option value="S">Si</option>
            <option value="N">No</option>
          </select>
        </div>
        <div className="col-lg-6 col-12 my-2">
          <span>¿A qué cosas?:</span>
          <input name="D_ALERGICO" className="form-control" type="text" />
        </div>
      </div>
      <hr />
      <h3 className="color-platform text-center">¿Padece o padeció alguna de las siguientes enfermedades?</h3>
      <div className="row">
        <div className="col-lg-6 col-12 my-2">
          <span>Escoliosis o enfermedades de columna:</span>
          <select className="form-select" name="M_ENF_COLUMNA">
            <option value=""></option>
            <option value="S">Si</option>
            <option value="N">No</option>
          </select>
        </div>
        <div className="col-lg-6 col-12 my-2">
          <span>Asma:</span>
          <select className="form-select" name="M_ASMA">
            <option value=""></option>
            <option value="S">Si</option>
            <option value="N">No</option>
          </select>
        </div>
        <div className="col-lg-6 col-12 my-2">
          <span>Enfermedades de los huesos o articulaciones:</span>
          <select className="form-select" name="M_ENF_HUESOS_ARTICULACIONES">
            <option value=""></option>
            <option value="S">Si</option>
            <option value="N">No</option>
          </select>
        </div>
        <div className="col-lg-6 col-12 my-2">
          <span>Anemia:</span>
          <select className="form-select" name="M_ANEMIA">
            <option value=""></option>
            <option value="S">Si</option>
            <option value="N">No</option>
          </select>
        </div>
        <div className="col-lg-6 col-12 my-2">
          <span>Enfermedades de los intestinos:</span>
          <select className="form-select" name="M_ENF_INTESTINOS">
            <option value=""></option>
            <option value="S">Si</option>
            <option value="N">No</option>
          </select>
        </div>
        <div className="col-lg-6 col-12 my-2">
          <span>Anorexia:</span>
          <select className="form-select" name="M_ANOREXIA">
            <option value=""></option>
            <option value="S">Si</option>
            <option value="N">No</option>
          </select>
        </div>
        <div className="col-lg-6 col-12 my-2">
          <span>Enfermedades renales crónicas:</span>
          <select className="form-select" name="M_ENF_RENALES">
            <option value=""></option>
            <option value="S">Si</option>
            <option value="N">No</option>
          </select>
        </div>
        <div className="col-lg-6 col-12 my-2">
          <span>Bulimia:</span>
          <select className="form-select" name="M_BULIMIA">
            <option value=""></option>
            <option value="S">Si</option>
            <option value="N">No</option>
          </select>
        </div>
        <div className="col-lg-6 col-12 my-2">
          <span>Enfermedades psiquiátricas:</span>
          <select className="form-select" name="M_ENF_PSIQUIATRICAS">
            <option value=""></option>
            <option value="S">Si</option>
            <option value="N">No</option>
          </select>
        </div>
        <div className="col-lg-6 col-12 my-2">
          <span>Fracturas:</span>
          <select className="form-select" name="M_FRACTURAS">
            <option value=""></option>
            <option value="S">Si</option>
            <option value="N">No</option>
          </select>
        </div>
        <div className="col-lg-6 col-12 my-2">
          <span>Enfermedades cardiacas:</span>
          <select className="form-select" name="M_ENF_CARDIACAS">
            <option value=""></option>
            <option value="S">Si</option>
            <option value="N">No</option>
          </select>
        </div>
        <div className="col-lg-6 col-12 my-2">
          <span>Diabetes:</span>
          <select className="form-select" name="M_DIABETES">
            <option value=""></option>
            <option value="S">Si</option>
            <option value="N">No</option>
          </select>
        </div>
        <div className="col-lg-6 col-12 my-2">
          <span>Epilepsia o convulsiones:</span>
          <select className="form-select" name="M_EPILEPSIA_CONVULSIONES">
            <option value=""></option>
            <option value="S">Si</option>
            <option value="N">No</option>
          </select>
        </div>
        <div className="col-lg-6 col-12 my-2">
          <span>Problemas de alimentación:</span>
          <select className="form-select" name="M_PRO_ALIMENTACION">
            <option value=""></option>
            <option value="S">Si</option>
            <option value="N">No</option>
          </select>
        </div>
        <div className="col-lg-6 col-12 my-2">
          <span>Fiebre reumática:</span>
          <select className="form-select" name="M_FIEBRE_REUMATICA">
            <option value=""></option>
            <option value="S">Si</option>
            <option value="N">No</option>
          </select>
        </div>
        <div className="col-lg-6 col-12 my-2">
          <span>Gripe:</span>
          <select className="form-select" name="M_GRIPE">
            <option value=""></option>
            <option value="S">Si</option>
            <option value="N">No</option>
          </select>
        </div>
        <div className="col-lg-6 col-12 my-2">
          <span>Gastritis o úlcera:</span>
          <select className="form-select" name="M_GASTITIS_ULCERA">
            <option value=""></option>
            <option value="S">Si</option>
            <option value="N">No</option>
          </select>
        </div>
        <div className="col-lg-6 col-12 my-2">
          <span>Cólicos renales:</span>
          <select className="form-select" name="M_COLICOS_RENALES">
            <option value=""></option>
            <option value="S">Si</option>
            <option value="N">No</option>
          </select>
        </div>
        <div className="col-lg-6 col-12 my-2">
          <span>Hipertensión arterial:</span>
          <select className="form-select" name="M_HIPERTENSION">
            <option value=""></option>
            <option value="S">Si</option>
            <option value="N">No</option>
          </select>
        </div>
        <div className="col-lg-6 col-12 my-2">
          <span>Hepatitis:</span>
          <select className="form-select" name="M_HEPATITIS">
            <option value=""></option>
            <option value="S">Si</option>
            <option value="N">No</option>
          </select>
        </div>
        <div className="col-lg-6 col-12 my-2">
          <span>Infecciones urinarias:</span>
          <select className="form-select" name="M_INFECCIONES_URINARIAS">
            <option value=""></option>
            <option value="S">Si</option>
            <option value="N">No</option>
          </select>
        </div>
        <div className="col-lg-6 col-12 my-2">
          <span>Problemas ginecológicos:</span>
          <select className="form-select" name="M_PROBLEMAS_GINECOLOGICOS">
            <option value=""></option>
            <option value="S">Si</option>
            <option value="N">No</option>
          </select>
        </div>
        <div className="col-lg-6 col-12 my-2">
          <span>Enfermedades de los pulmones:</span>
          <select className="form-select" name="M_ENF_PULMONES">
            <option value=""></option>
            <option value="S">Si</option>
            <option value="N">No</option>
          </select>
        </div>
        <div className="col-lg-6 col-12 my-2">
          <span>¿Cuales?:</span>
            <input name="D_ENF_PULMONES" className="form-control" type="text" />
        </div>
      </div>
      <hr />
      <div className="text-center mt-4">
        <Button title="Volver" secondary onPress={handleClickVolver} />
        <Button title="Continuar" onLoad={isFetching} onPress={handleClickValidar} />
      </div>
      {apiMessage ? <div dangerouslySetInnerHTML={{ __html: apiMessage }}></div> : <></>}
    </form>
  )
}