import { useEffect, useState } from "react";
import { Outlet, useSearchParams } from "react-router-dom";
import NotFound from "../pages/NotFound";

export default function Public(){
  const [searchParams] = useSearchParams();

  const [key, setKey] = useState('');

  useEffect(() => {
        const _key = searchParams.get("__key");
        setKey(_key ? String(_key) : '');
  }, [searchParams])

  return (
    <>
    {
      key === process.env.REACT_APP_KEY
      ? <Outlet />
      : <NotFound message="" />
    }
    </>
  )
}