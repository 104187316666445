import { Action } from "redux";
import { SET_ROUTE } from "../types";
import IRoute from '../../interfaces/IRoute';

let initialState: IRoute = {
  section: 'usuario',
  sub: 'tus-datos',
  cp: '',
};

class RouteAction implements Action {
  type: string;
  payload: IRoute;
  constructor(type: string, payload: IRoute) {
    this.type = type;
    this.payload = payload;
  }
}

export default function RouteReducer(state: IRoute = initialState, action: RouteAction) {
  switch (action.type) {
    case SET_ROUTE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}