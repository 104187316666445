import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Api from "../../utils/api";
import { setRoute } from "../../store/actions/routeActions";
import { useDispatch } from 'react-redux';
import { SkeletonTable } from "../../components/Skeleton";


interface IPCursor {
  M_VALIDO: string;
  NOMBRE: string;
  TIENE_FD: string;
}

interface IResponse {
  PD_ERROR: string;
  PM_ERROR: string;
  PCURSOR: IPCursor[];
  mensaje: any;
}

export default function GrupoDeViaje() {
  const [grupoDeViaje, setGrupoDeViaje] = useState<Array<IPCursor>>([])
  const [onLoad, setOnLoad] = useState(true);

  const { id = "-1" } = useParams();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setRoute({
      section: 'pasajero',
      sub: 'grupo-de-viaje',
      cp: id,
    }));
  }, [dispatch, id]);

  useEffect(() => {
    setGrupoDeViaje([]);
    Api("consumir").post({
      servicio: "paxGrupo",
      param: {
        pco_pasajero: id,
      },
    }).then((res: IResponse) => {
      setOnLoad(false);
      setGrupoDeViaje(res.PCURSOR);
    });
  }, [id]);

  return (<div className="container-data p-md-5 p-1">
    <div className="container">
      {
        onLoad
          ? <SkeletonTable />
          : <table className="table table-striped">
            <thead>
              <tr className="th-platform"><th colSpan={2}>Grupo de viaje</th></tr>
            </thead>
            <tbody>
              {
                grupoDeViaje.map((dato: IPCursor, index: number) => (
                  <tr key={dato.NOMBRE + dato + index}>
                    <td className="ps-5">{index + 1}º</td>
                    <td>{dato.NOMBRE}</td>
                  </tr>
                ))
              }
            </tbody>
          </table>
      }
    </div>
  </div>)
}