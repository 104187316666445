import { Action } from "redux";
import { SET_INPUT_FICHA_MEDICA, BACK_STEEP_FICHA_MEDICA, NEXT_STEEP_FICHA_MEDICA } from "../types";
import IFichaMedicaCarga, { fichaCargaMedicaEmpty } from "../../interfaces/IFichaMedicaCarga";

let initialState: IFichaMedicaCarga = fichaCargaMedicaEmpty;

class FichaMedicaCargaAction implements Action {
  type: string;
  payload: IFichaMedicaCarga;
  constructor(type: string, payload: IFichaMedicaCarga) {
    this.type = type;
    this.payload = payload;
  }
}

export default function FichaMedicaReducer(state: IFichaMedicaCarga = initialState, action: FichaMedicaCargaAction) {
  switch (action.type) {
    case SET_INPUT_FICHA_MEDICA:
      return { ...state, ...action.payload };
    case BACK_STEEP_FICHA_MEDICA:
      return { ...state, steep: state.steep - 1 };
    case NEXT_STEEP_FICHA_MEDICA:
      return { ...state, steep: state.steep + 1 };
    default:
      return state;
  }
}