import React, { useState, useEffect } from 'react'
import { useParams } from "react-router-dom"
import Api from '../../utils/api'
import { useDispatch } from 'react-redux'
import { setRoute } from "../../store/actions/routeActions";

import ServiciosPreViaje from '../../components/serviciosIndividuales/ServiciosPreViaje';
import ServiciosVouchers from '../../components/serviciosIndividuales/ServiciosVouchers';
import IVoucher from '../../interfaces/IVoucher';

export default function ServiciosIndividuales() {
  const [messageApi, setMessageApi] = useState("");
  const [, setIsLoad] = useState(true);

  const [hasMercadoPago, setHasMercadoPago] = useState(false);
  const [hasPagoTarjeta, setHasPagoTarjeta] = useState(false);

  const [serviciosIndividuales, setServiciosIndividuales] = useState([]);
  const [vouchers, setVouchers] = useState<Array<IVoucher>>([]);

  const { id = "-1" } = useParams();
  const dispatch = useDispatch();


  useEffect(() => {
    dispatch(setRoute({
      section: 'pasajero',
      sub: 'servicios-individuales',
      cp: id,
    }));
  }, [dispatch, id]);

  useEffect(() => {
    Api("consumir")
      .post({
        param: {
          pco_pasajero: id
        },
        servicio: 'paxSerIndividual'
      }).then(res => {
        setIsLoad(false);
        setMessageApi(res.PD_MENSAJE);
        setHasMercadoPago(res.PI_ACTIVAR_MERCADOPAGO !== "0");
        setHasPagoTarjeta(res.PI_ACTIVAR_PAGOTARJETA !== "0");
        setServiciosIndividuales(res.PREF_SERIND.map((e: any) => ({
          ...e,
          BOT_IMPRIMIR: e.BOT_IMPRIMIR === 'S',
          IMP_PAGAR: Number(e.IMP_PAGAR),
        })))
        setVouchers(res.PREF_VOUCHER);
      })
  }, [id]);

  return (
    <div className="container-data">
      <div className="message-api" dangerouslySetInnerHTML={{ __html: messageApi }}></div>
      <ServiciosPreViaje
        servicios={serviciosIndividuales}
        hasMercadoPago={hasMercadoPago}
        hasPagoTarjeta={hasPagoTarjeta}
      />
      <ServiciosVouchers
        vouchers={vouchers}
      />
    </div>
  )
}
