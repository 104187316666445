import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, IconDefinition } from '@fortawesome/free-solid-svg-icons';

export default function Button({title = "Aceptar", onPress, onLoad = false, secondary = false, block = false, icon, disabled = false}: {icon?: IconDefinition ,title?: string, onPress: Function, onLoad?: boolean, secondary?: boolean, block?: boolean, disabled?: boolean}){
  return (
    onLoad
    ? <span className={`btn btn-platform ${disabled ? 'disabled' : '' } ${block ? 'w-100' : ''}`}><FontAwesomeIcon icon={faSpinner} className="spinner"/> Cargando...</span>
    : <span className={`${secondary ? "btn btn-platform-out" : "btn btn-platform"} ${disabled ? 'disabled' : '' } ${block ? 'w-100' : ''}` } onClick={() => !disabled && onPress()}>
      {
        icon
        ? <FontAwesomeIcon icon={icon} className="me-1" />
        : <></>
      }
      
      {title}</span>
  )
}