import React, { useEffect, useState } from "react";
import { Outlet, Link, useLocation } from "react-router-dom";
import Api from "../utils/api";
import IPrefPasajeros from "../interfaces/IPrefPasajeros";
import { useDispatch, useSelector } from 'react-redux';
import { setPasajeros } from "../store/actions/pasajerosActions";
import { logout } from "../store/actions/authActions";
import { RootState } from '../store/reducers';

import useSesion from "../hooks/useSesion";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faTasks, faShuttleVan, faSignOut, faBars, faTicket } from '@fortawesome/free-solid-svg-icons';
import ImageLogo from "../assets/images/logo.png";

interface IResponse {
  PD_ERROR: string;
  PM_ERROR: string;
  PM_MENU_TURNO: string;
  PREF_DATOS: IPrefPasajeros[];
  PREF_SOLAPAS: any[];
  mensaje: any;
}

export default function PanelLogeado() {
  useSesion();
  const pasajeros = useSelector((state: RootState) => state.pasajeros);
  const auth = useSelector((state: RootState) => state.auth);
  const route = useSelector((state: RootState) => state.route);
  const [toggle, setToggle] = useState(false);
  const [togglePasajero, setTogglePasajero] = useState<Array<Boolean>>([]);
  const [toggleSolicitudes, setToggleSolicitudes] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  useEffect(() => {
    setToggle(false);
  }, [location])

  useEffect(() => {
    if (pasajeros.pasajeros.length) {
      setTogglePasajero(Array(pasajeros.pasajeros.length));
      return;
    }

    Api("consumir").post({
      servicio: "usuarioPasajeros",
    }).then((res: IResponse) => {
      res.PREF_DATOS = res.PREF_DATOS.map((e: IPrefPasajeros) => ({ ...e, SOLAPAS: res.PREF_SOLAPAS.filter(f => f.CO_PASAJERO === e.CO_PASAJERO).map(f => f.SOLAPA) }));
      dispatch(setPasajeros({ pasajeros: res.PREF_DATOS }));
      setTogglePasajero(Array(res.PREF_DATOS.length));
    });
  }, [dispatch, pasajeros.pasajeros.length]);

  function handleToggleMenu() {
    setToggle(!toggle);
  }

  function handleToggleSolicitudes() {
    setToggleSolicitudes(!toggleSolicitudes);
  }

  function handleTogglePasajero(i: number) {
    const _togglePasajero = [...togglePasajero];
    _togglePasajero[i] = !_togglePasajero[i];
    setTogglePasajero(_togglePasajero);
  }

  function handleCloseSesion() {
    dispatch(logout());
  }

  return (
    <div>
      <nav className="nav-sidebar pt-5 d-none d-xl-block">
        <div className="logo-layout mx-auto" style={{ backgroundImage: `url('${ImageLogo}')` }}></div>
        <ul className="list-unstyled">
          <li className={route.section === "usuario" ? 'active active-top' : ''} >
            <Link className={route.section === "usuario" ? 'active-down' : ''} to="/usuario/tus-datos"><FontAwesomeIcon icon={faUser} /> Usuario</Link>
          </li>

          <li className={route.section === "solicitudes" ? 'active active-top' : ''}>
            <Link className={route.section === "solicitudes" ? 'active-down' : ''} to="/solicitudes/tus-solicitudes"><FontAwesomeIcon icon={faTasks} /> Solicitudes</Link>
          </li>
          <li className={route.section === "turno" ? 'active active-top' : ''}>
            <Link className={route.section === "turno" ? 'active-down' : ''} to="/turno/ver-turnos" ><FontAwesomeIcon icon={faTicket} /> Turno</Link>
          </li>
          {
            pasajeros.pasajeros.map((pasajero: IPrefPasajeros) => (
              <li className={route.section === "pasajero" && route.cp === pasajero.CO_PASAJERO ? 'active active-top' : ''} key={'k' + pasajero.CO_PASAJERO}>
                <Link className={route.section === "pasajero" && route.cp === pasajero.CO_PASAJERO ? 'active-down' : ''} to={`/pasajero/${pasajero.CO_PASAJERO}/${pasajero.D_APEYNOM.replace("*", "").replaceAll(" ", "-")}/datos-del-pasajero`}><FontAwesomeIcon icon={faShuttleVan} /> {pasajero.D_APEYNOM}</Link>
              </li>
            ))
          }
          <li>
            <span className="cursor-pointer" onClick={handleCloseSesion}><FontAwesomeIcon icon={faSignOut} /> Cerrar sesión</span>
          </li>
        </ul>
      </nav>
      <div id="nav-mobile" className="d-xl-none">
        <nav className="navbar navbar-expand-xl navbar-light" id="nav-mobile-header">
          <span className="navbar-brand" >
            <div className="sidebar-header navbar-logo" style={{ backgroundImage: `url('${ImageLogo}')` }}>
            </div>
          </span>
          <span onClick={handleToggleMenu} className="navbar-toggler collapsed me-3">
            <span><FontAwesomeIcon icon={faBars} /></span>
          </span>
          <div className={toggle ? 'navbar-collapse' : 'navbar-collapse collapse'} id="navbarSupportedContent">
            <ul className="list-unstyled components">
              <li>
                <Link to="/usuario/tus-datos" ><FontAwesomeIcon icon={faUser} /> Usuario</Link>
              </li>
              <li>
                <Link to="/turno/agendar" ><FontAwesomeIcon icon={faTicket} /> Turno</Link>
              </li>
              <li>
                <span onClick={handleToggleSolicitudes} className="dropdown-toggle"><FontAwesomeIcon icon={faTasks} /> Solicitudes</span>
              </li>
              <li className={toggleSolicitudes ? 'm-subli-solicitudes' : 'd-none'}>
                <Link to={'/solicitudes/tus-solicitudes'} className="pl-4" >Solicitudes ingresadas</Link>
              </li>
              <li className={toggleSolicitudes ? 'm-subli-solicitudes' : 'd-none'}>
                <Link to={'/solicitudes/nueva'} className="pl-4" >Solicitud nueva</Link>
              </li>
              {
                pasajeros.pasajeros.map((pasajero: IPrefPasajeros, i) => (
                  <div key={'m-' + pasajero.CO_PASAJERO}>
                    <li>
                      <span onClick={() => handleTogglePasajero(i)} className="dropdown-toggle"><FontAwesomeIcon icon={faShuttleVan} /> {pasajero.D_APEYNOM}</span>
                    </li>
                    <div className={togglePasajero[i] ? '' : 'd-none'}>
                      {pasajero.SOLAPAS.includes("Datos") && <li><Link to={`/pasajero/${pasajero.CO_PASAJERO}/${pasajero.D_APEYNOM.replace("*", "").replaceAll(" ", "-")}/datos-del-pasajero`}>Datos del pasajero</Link></li>}
                      {pasajero.SOLAPAS.includes("CuotasPagos") && <li><Link to={`/pasajero/${pasajero.CO_PASAJERO}/${pasajero.D_APEYNOM.replace("*", "").replaceAll(" ", "-")}/cuotas`}>Cuotas y pago</Link></li>}
                      {pasajero.SOLAPAS.includes("Grupos") && <li><Link to={`/pasajero/${pasajero.CO_PASAJERO}/${pasajero.D_APEYNOM.replace("*", "").replaceAll(" ", "-")}/grupo-de-viaje`}>Grupo de viaje</Link></li>}
                      {pasajero.SOLAPAS.includes("CuotaCero") && <li><Link to={`/pasajero/${pasajero.CO_PASAJERO}/${pasajero.D_APEYNOM.replace("*", "").replaceAll(" ", "-")}/cuota-cero`}>Cuota cero</Link></li>}
                      {pasajero.SOLAPAS.includes("FichaMedica") && <li><Link to={`/pasajero/${pasajero.CO_PASAJERO}/${pasajero.D_APEYNOM.replace("*", "").replaceAll(" ", "-")}/ficha-medica`}>Ficha médica</Link></li>}
                      {pasajero.SOLAPAS.includes("ServicioIndividual") && <li><Link to={`/pasajero/${pasajero.CO_PASAJERO}/${pasajero.D_APEYNOM.replace("*", "").replaceAll(" ", "-")}/servicios-individuales`}>Servicios individuales</Link></li>}
                      {pasajero.SOLAPAS.includes("Salida") && <li><Link to={`/pasajero/${pasajero.CO_PASAJERO}/${pasajero.D_APEYNOM.replace("*", "").replaceAll(" ", "-")}/lugar-horario-salida`}>Lugar y horario de salida</Link></li>}
                      {pasajero.SOLAPAS.includes("TipsParaTuViaje") && <li><span>Tips para tu viaje</span></li>}
                    </div>
                  </div>
                ))
              }
              <li className="cursor-pointer"><span onClick={handleCloseSesion}><FontAwesomeIcon icon={faSignOut} /> Cerrar sesión</span></li>
            </ul>
          </div>
        </nav>
      </div>
      <h3 className="mb-2 text-end pe-2 pt-2 color-platform text-capitalize d-none d-xl-block">{auth.nombre.toLocaleLowerCase()} {auth.apellido.toLocaleLowerCase()}</h3>
      <div className="container-dashboard">
        <Outlet />
      </div>
    </div>
  )
}
