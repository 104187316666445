import ICodigoDato from "./ICodigoDato";

export interface ICamposFichaMedica {
  CE1_D_APELLIDO: string;
  CE1_D_EMAIL: string;
  CE1_D_NOMBRE: string;
  CE1_N_CEL_DDN: string;
  CE1_N_CEL_NRO: string;
  CE1_N_TEL_DDN: string;
  CE1_N_TEL_NRO: string;
  CE1_T_PARENTESCO: string;
  CE2_D_APELLIDO: string;
  CE2_D_EMAIL: string;
  CE2_D_NOMBRE: string;
  CE2_N_CEL_DDN: string;
  CE2_N_CEL_NRO: string;
  CE2_N_TEL_DDN: string;
  CE2_N_TEL_NRO: string;
  CE2_T_PARENTESCO: string;
  C_DIETA: string;
  C_NACIONALIDAD: string;
  C_PAIS: string;
  C_POSTAL: string;
  C_PROVINCIA: string;
  C_SEXO: string;
  D_AF_DESACONSEJADA: string;
  D_ALERGICO: string;
  D_ALIMENTO_DESACONSEJADO: string;
  D_APELLIDO: string;
  D_CALLE: string;
  D_CIRUGIA: string;
  D_DATOS_AMPLIADOS: string;
  D_DOSIS_FRECUENCIA: string;
  D_ENFERMEDAD: string;
  D_ENF_PULMONES: string;
  D_GRUPO_SANGUINEO: string;
  D_INTERNACION: string;
  D_LOCALIDAD: string;
  D_MAIL: string;
  D_MEDICAMENTO: string;
  D_MEDICAMENTOS: string;
  D_MEDICO_CABECERA: string;
  D_MED_NO_DEBE_TOMAR: string;
  D_NOMBRE: string;
  D_OBRA_SOCIAL: string;
  D_PADECIO_ENF: string;
  D_VIAJO_EXTERIROR: string;
  F_ANTICUERPOS_COVID19: string; // Date
  F_NACIMIENTO: string; // Date
  F_PCR_COVID19: string; // Date
  F_VACUNA_H1N1: string; // Date
  F_VEN_DOCUMENTO: string; // Date
  M_AF_DESACONSEJADA: string;
  M_ALERGICO: string;
  M_ALIMENTO_DESACONSEJADO: string;
  M_ANEMIA: string;
  M_ANOREXIA: string;
  M_ANTICUERPOS_COVID19: string;
  M_ASMA: string;
  M_BULIMIA: string;
  M_CIRUGIA: string;
  M_COLICOS_RENALES: string;
  M_COVID19: string;
  M_DIABETES: string;
  M_ENF_CARDIACAS: string;
  M_ENF_COLUMNA: string;
  M_ENF_HUESOS_ARTICULACIONES: string;
  M_ENF_INTESTINOS: string;
  M_ENF_PSIQUIATRICAS: string;
  M_ENF_PULMONES: string;
  M_ENF_RENALES: string;
  M_EPILEPSIA_CONVULSIONES: string;
  M_FIEBRE_REUMATICA: string;
  M_FRACTURAS: string;
  M_GASTITIS_ULCERA: string;
  M_GRIPE: string;
  M_HEPATITIS: string;
  M_HIPERTENSION: string;
  M_INFECCIONES_URINARIAS: string;
  M_INTERNACION: string;
  M_MEDICAMENTO: string;
  M_MEDICAMENTOS: string;
  M_MED_NO_DEBE_TOMAR: string;
  M_PADECIO_ENF: string;
  M_PCR_COVID19: string;
  M_PROBLEMAS_GINECOLOGICOS: string;
  M_PRO_ALIMENTACION: string;
  M_TRATAMIENTO_MEDICO: string;
  M_VACUNA_H1N1: string;
  M_VIAJO_EXTERIOR: string;
  N_AFILIADO: string;
  N_CEL_DDN: string;
  N_CEL_NRO: string;
  N_DOCUMENTO: string;
  N_MEDICO_DDN: string;
  N_MEDICO_NRO: string;
  N_TEL_DDN: string;
  N_TEL_NRO: string;
  PMT_D_APELLIDO: string;
  PMT_D_EMAIL: string;
  PMT_D_NOMBRE: string;
  PMT_N_CEL_DDN: string;
  PMT_N_CEL_NRO: string;
  PMT_N_TEL_DDN: string;
  PMT_N_TEL_NRO: string;
  PMT_T_PARENTESCO: string;
  T_DOCUMENTO: string;
}

export default interface IFichaCargaMedica {
  campos: ICamposFichaMedica;
  dietas: ICodigoDato[];
  sexos: ICodigoDato[];
  nacionalidades: ICodigoDato[];
  parentescos: ICodigoDato[];
  provincias: ICodigoDato[];
  docuementos: ICodigoDato[];
  steep: number;
}

export const datosFichaMedicaEmpty: ICamposFichaMedica  = {
  CE1_D_APELLIDO: "",
  CE1_D_EMAIL: "",
  CE1_D_NOMBRE: "",
  CE1_N_CEL_DDN: "",
  CE1_N_CEL_NRO: "",
  CE1_N_TEL_DDN: "",
  CE1_N_TEL_NRO: "",
  CE1_T_PARENTESCO: "",
  CE2_D_APELLIDO: "",
  CE2_D_EMAIL: "",
  CE2_D_NOMBRE: "",
  CE2_N_CEL_DDN: "",
  CE2_N_CEL_NRO: "",
  CE2_N_TEL_DDN: "",
  CE2_N_TEL_NRO: "",
  CE2_T_PARENTESCO: "",
  C_DIETA: "",
  C_NACIONALIDAD: "",
  C_PAIS: "",
  C_POSTAL: "",
  C_PROVINCIA: "",
  C_SEXO: "",
  D_AF_DESACONSEJADA: "",
  D_ALERGICO: "",
  D_ALIMENTO_DESACONSEJADO: "",
  D_APELLIDO: "",
  D_CALLE: "",
  D_CIRUGIA: "",
  D_DATOS_AMPLIADOS: "",
  D_DOSIS_FRECUENCIA: "",
  D_ENFERMEDAD: "",
  D_ENF_PULMONES: "",
  D_GRUPO_SANGUINEO: "",
  D_INTERNACION: "",
  D_LOCALIDAD: "",
  D_MAIL: "",
  D_MEDICAMENTO: "",
  D_MEDICAMENTOS: "",
  D_MEDICO_CABECERA: "",
  D_MED_NO_DEBE_TOMAR: "",
  D_NOMBRE: "",
  D_OBRA_SOCIAL: "",
  D_PADECIO_ENF: "",
  D_VIAJO_EXTERIROR: "",
  F_ANTICUERPOS_COVID19: "", // Date
  F_NACIMIENTO: "", // Date
  F_PCR_COVID19: "", // Date
  F_VACUNA_H1N1: "", // Date
  F_VEN_DOCUMENTO: "", // Date
  M_AF_DESACONSEJADA: "",
  M_ALERGICO: "",
  M_ALIMENTO_DESACONSEJADO: "",
  M_ANEMIA: "",
  M_ANOREXIA: "",
  M_ANTICUERPOS_COVID19: "",
  M_ASMA: "",
  M_BULIMIA: "",
  M_CIRUGIA: "",
  M_COLICOS_RENALES: "",
  M_COVID19: "",
  M_DIABETES: "",
  M_ENF_CARDIACAS: "",
  M_ENF_COLUMNA: "",
  M_ENF_HUESOS_ARTICULACIONES: "",
  M_ENF_INTESTINOS: "",
  M_ENF_PSIQUIATRICAS: "",
  M_ENF_PULMONES: "",
  M_ENF_RENALES: "",
  M_EPILEPSIA_CONVULSIONES: "",
  M_FIEBRE_REUMATICA: "",
  M_FRACTURAS: "",
  M_GASTITIS_ULCERA: "",
  M_GRIPE: "",
  M_HEPATITIS: "",
  M_HIPERTENSION: "",
  M_INFECCIONES_URINARIAS: "",
  M_INTERNACION: "",
  M_MEDICAMENTO: "",
  M_MEDICAMENTOS: "",
  M_MED_NO_DEBE_TOMAR: "",
  M_PADECIO_ENF: "",
  M_PCR_COVID19: "",
  M_PROBLEMAS_GINECOLOGICOS: "",
  M_PRO_ALIMENTACION: "",
  M_TRATAMIENTO_MEDICO: "",
  M_VACUNA_H1N1: "",
  M_VIAJO_EXTERIOR: "",
  N_AFILIADO: "",
  N_CEL_DDN: "",
  N_CEL_NRO: "",
  N_DOCUMENTO: "",
  N_MEDICO_DDN: "",
  N_MEDICO_NRO: "",
  N_TEL_DDN: "",
  N_TEL_NRO: "",
  PMT_D_APELLIDO: "",
  PMT_D_EMAIL: "",
  PMT_D_NOMBRE: "",
  PMT_N_CEL_DDN: "",
  PMT_N_CEL_NRO: "",
  PMT_N_TEL_DDN: "",
  PMT_N_TEL_NRO: "",
  PMT_T_PARENTESCO: "",
  T_DOCUMENTO: "",
}

export const fichaCargaMedicaEmpty: IFichaCargaMedica = {
  campos: datosFichaMedicaEmpty,
  dietas: [],
  sexos: [],
  nacionalidades: [],
  parentescos: [],
  provincias: [],
  docuementos: [],
  steep: 0,
}