import React, { useEffect, useState } from 'react'
import Button from '../../components/Button';
import Api from '../../utils/api';

export default function AgendarTurno() {
  const [sucursal, setSucursal] = useState<string>("");
  const [sucursales, setSucursales] = useState<Array<any> | null>(null);

  const [fecha, setFecha] = useState<string>("");
  const [fechas, setFechas] = useState<Array<any> | null>(null);

  const [horario, setHorario] = useState<string>("");
  const [horarios, setHorarios] = useState<Array<any> | null>(null);

  const [apiMessage, setApiMessage] = useState("");

  useEffect(() => {
    Api("consumir")
      .post({
        servicio: "turnoSucursal"
      })
      .then(res => {
        setApiMessage(res.mensaje.descripcion)
        setSucursales(res.PREF_SUCURSALES);
      })
  }, [])

  useEffect(() => {
    if(!sucursal){
      return;
    }
    setFecha("");
    setHorario("");
    setFechas(null);
    setHorarios(null);
    Api("consumir")
    .post({
      servicio: "turnoFechas",
      param: {
        pco_sucursal: sucursal,
      }
    })
    .then(res => {
      setApiMessage(res.mensaje.descripcion)
      setFechas(res.PREF_FECHAS);
      
    })
  }, [sucursal]);

  useEffect(() => {
    if(!fecha){
      return
    }
    setHorario("");
    setHorarios(null);
    Api("consumir")
    .post({
      servicio: "turnoHorarios",
      param: {
        pco_sucursal: sucursal,
        p_fecha: fecha
      }
    })
    .then(res => {
      setApiMessage(res.mensaje.descripcion)
      setHorarios(res.PREF_HORARIOS);
    })
  }, [sucursal, fecha]);

  const SelectSucursal = () => {
    if (!sucursales) {
      return <span>Cargando...</span>
    }
    return (<div className="mb-3">
      <span>Elije una Sucursal</span>
      <select className="form-select" defaultValue={sucursal} onChange={(e) => setSucursal(e.target.value)}>
        <option value="">Seleccione una sucursal</option>
        {sucursales.map(row => <option key={row.CO_SUCURSAL} value={row.CO_SUCURSAL}>{row.SUCURSAL}</option>)}
      </select>
    </div>)
  }

  const SelectFecha = () => {
    if (!sucursal) {
      return (<></>);
    }
    if (!fechas) {
      return <span>Cargando...</span>
    }
    return (<div className="mb-3">
      <span>Fecha para el turno</span>
      <select className="form-select" defaultValue={fecha} onChange={(e) => setFecha(e.target.value)}>
        <option value="">Seleccione una fecha</option>
        {fechas.map(row => <option key={row.FECHA} value={row.FECHA}>{row.INFO}</option>)}
      </select>
    </div>)
  }

  const SelectHorario = () => {
    if (!(sucursal && fecha)) {
      return (<></>);
    }
    if (!horarios) {
      return <span>Cargando...</span>
    }
    return (<div className="mb-3">
    <span>Hora para el turno</span>
    <select className="form-select" defaultValue={horario} onChange={(e) => setHorario(e.target.value)}>
      <option value="">Seleccione un horario</option>
      {horarios.map(row => <option key={row.PID} value={row.PID}>{row.HORARIO} [{row.PUESTO}]</option>)}
    </select>
  </div>)
  }

  return (
    <div>
      <h3 className="color-platform mb-5">Agendar Turno</h3>
      <div className="container-data">
        <div className="mx-auto" style={{ maxWidth: '20em' }}>
          {SelectSucursal()}
          {SelectFecha()}
          {SelectHorario()}
          {
            sucursal && fecha && horario
              ? <div className="mb-3 text-center">
                <Button title="Confirmar turno" onPress={() => { }} />
              </div>
              : <></>
          }
          <p className="text-center text-danger">{apiMessage}</p>
        </div>
      </div>
    </div>
  )
}
