import { useEffect } from "react";
import {useLocation} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from "../store/reducers";
import { logout } from '../store/actions/authActions'

function useSesion(){
  const location = useLocation();
  const dispatch = useDispatch();
  const auth = useSelector((store: RootState) => store.auth);
  useEffect(() => {
    if(auth.sesionTime < Date.now()){
      dispatch(logout());
    }
  }, [location, auth, dispatch])
}

export default useSesion;