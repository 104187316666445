import React, { useState, useRef, useEffect } from "react";
import Api from "../../utils/api";
import { useDispatch } from 'react-redux';
import { setRoute } from "../../store/actions/routeActions";
import Button from "../../components/Button";


export default function CambiarClave() {
  const [claveActual, setClaveActual] = useState("");
  const [claveNueva, setClaveNueva] = useState("");
  const [confirmarClaveNueva, setConfirmarClaveNueva] = useState("");
  const [messageApi, setMessageApi] = useState("");
  const [isFetching, setIsFetching] = useState(false);

  const form = useRef<HTMLFormElement>(null);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setRoute({
      section: 'usuario',
      sub: 'cambiar-clave',
      cp: '',
    }));
  }, [dispatch]);

  function handleChangePwd() {
    setMessageApi("");
    if (!form.current?.checkValidity()) {
      return;
    }

    if (claveNueva !== confirmarClaveNueva) {
      setMessageApi("la contraseñas no coinciden");
      return;
    }
    setIsFetching(true);
    Api("consumir").post({
      servicio: "cambioClave",
      param: {
        pd_clave_old: claveActual,
        pd_clave_new: claveNueva
      },
    }).then(res => {
      setIsFetching(false);
      if(res.mensaje.tipo === "E"){
        setMessageApi(res.mensaje.descripcion);
        return;
      }
    });

  }
  return (
    <div className="container-data">
      <form className="container w-lg-50" ref={form}>
        <div className="my-3">
          <span>Contraseña actual</span>
          <input className="form-control" type="password" name="clave-actual" onChange={(e) => setClaveActual(e.target.value)} placeholder="****" required />
        </div>
        <div className="my-3">
          <span>Contraseña nueva</span>
          <input className="form-control" type="password" name="clave-nueva" placeholder="*******" onChange={(e) => setClaveNueva(e.target.value)} required />
        </div>
        <div className="my-3">
          <span>Confirme contraseña nueva</span>
          <input className="form-control" type="password" name="confirmar-clave-nueva" placeholder="*******" onChange={(e) => setConfirmarClaveNueva(e.target.value)} required />
        </div>
        <div className="text-center mt-3">
          <Button title="cambiar contraseña" onLoad={isFetching} onPress={() => handleChangePwd()} />
        </div>
        <p className="text-danger mt-3">{messageApi}</p>
      </form>
    </div>)
}