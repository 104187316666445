import { faFileAlt } from '@fortawesome/free-solid-svg-icons';
import React, { useState } from 'react'
import { useParams } from 'react-router-dom';
import IVoucher from '../../interfaces/IVoucher'
import Api from '../../utils/api';
import DownloadFile from '../../utils/downloadFile';
import Button from '../Button';

import './ServiciosVouchers.css';

interface IPropsRow {
  voucher: IVoucher,
  i: number,
}

interface IProps {
  vouchers: IVoucher[]
}

function Row({ voucher, i }: IPropsRow) {
  const [print, setPrint] = useState<boolean>(false);
  const { id = "-1" } = useParams();

  const handleClickPrint = () => {
    setPrint(true);
    Api("birt")
      .post({
        param: {
          prm_co_pasajero: id,
          prm_nu_talon: voucher.N_VOUCHER,
        },
        reporte: "SV_cupon_SI.rptdesign"
      }, "text/plain")
      .then((res: string) => {
        DownloadFile(res, `${id} - Talon ${voucher.N_VOUCHER}`);
      })
      .finally(() => {
        setPrint(false);
      });
  }

  return (
    <div className="grid-row">
      <span title="Voucher" className="th-title">{i}</span>
      <span title="Nº Voucher">{voucher.N_VOUCHER}</span>
      <span title="Fecha">{voucher.FECHA_HORA}</span>
      <span title="Lista de servicios">{voucher.LIS_SERVICIOS}</span>
      <span title="Imprimir">{voucher.NU_FACTURA ? <Button icon={faFileAlt} title={voucher.NU_FACTURA} onLoad={print} onPress={() => handleClickPrint()} /> : <></>}</span>
    </div>);
}

export default function ServiciosVouchers({ vouchers }: IProps) {
  return (
    <div className="servicios-voucher mt-5">
      <h4>Vouchers:</h4>
      <div className="d-grid grid-container">
        <div className="th-platform">
          <span>Nº Voucher</span>
          <span>Fecha</span>
          <span>Lista de servicios</span>
          <span></span>
        </div>
        <div className="td-platform">
          {
            vouchers.map((voucher, i) => <Row key={voucher.NU_FACTURA} voucher={voucher} i={i + 1} />)
          }
        </div>
      </div>
    </div>
  )
}
