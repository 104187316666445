import React, { useRef, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, Link } from "react-router-dom";
import { RootState } from '../../store/reducers';
import Api from '../../utils/api';
import Button from '../../components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faChevronCircleLeft } from '@fortawesome/free-solid-svg-icons';
import Modal from '../../components/Modal';

interface IModalProps {
  show: boolean;
  body: string;
}

export default function Registro() {
  const [messageApi, setMessageApi] = useState("");
  const [correo, setCorreo] = useState("");
  const [apellido, setApellido] = useState("");
  const [nombre, setNombre] = useState("");
  const [tipoDni, setTipoDni] = useState("1");
  const [dni, setDni] = useState("");
  const [onFetch, setonFetch] = useState(false);
  const [modal, setModal] = useState<IModalProps>({ show: false, body: "<br>Usuario creado.<br>Verifique su correo electrónico para obtener la clave de acceso. (Tenga en cuenta las carpetas SPAM/NO DESADOS)" })

  const form = useRef<HTMLFormElement>(null);
  // navigate
  const navigate = useNavigate();

  // state
  const state = useSelector((state: RootState) => state);

  function handleLogin() {
    setonFetch(true);
    setMessageApi("");
    Api("usuarioAlta").post({
      mail: correo,
      apellido,
      nombre,
      tipoDocumento: tipoDni,
      nroDocumento: dni,
    })
      .then(res => {
        setonFetch(false);
        if (res.mensaje.tipo === "E") {
          setMessageApi(res.mensaje.descripcion);
          return;
        }
        setModal({show: true, body: res.mensaje.descripcion});
      });
  }

  useEffect(() => {
    document.querySelector("body")?.classList.add("bg-login");
    if (!state.auth.jwt) {
      return;
    }
    document.querySelector("body")?.classList.remove("bg-login");
    navigate("/usuario/tus-datos");
  }, [state, navigate]);

  return (
    <div className="bg-login h-100 pb-sm-5 pb-3 mt-sm-3 pt-1 px-3">
        <Link to="/iniciar-sesion" className="text-white fs-3 text-decoration-none"><FontAwesomeIcon icon={faChevronCircleLeft} className="me-2" />volver</Link>
      <h1 className="h1-login-title d-flex justify-content-center mx-auto mb-sm-5 mb-2">{process.env.REACT_APP_PLATFORM} - Sucursal Virtual</h1>
      <div className="mx-auto my-auto bg-light rounded shadow-lg container-recuperar-pwd">
        <div className="">
          <div className="row">
            <div className="col-12 bg-white rounded-2 p-md-5 p-1 px-3">
              <h1 className="color-platform text-center mb-5">Registrar usuario</h1>
              <form ref={form}>
                <div className="my-3">
                  <span className="fw-bold">Correo electrónico</span>
                  <input className="form-control" type="email" name="usuario" onChange={(e) => setCorreo(e.target.value)} placeholder="ejemplo@correo.com" required />
                </div>
                <div className="my-3">
                  <span className="fw-bold">Apellido</span>
                  <input className="form-control" type="text" name="usuario" onChange={(e) => setApellido(e.target.value)} placeholder="" required />
                </div>
                <div className="my-3">
                  <span className="fw-bold">Nombre</span>
                  <input className="form-control" type="text" name="usuario" onChange={(e) => setNombre(e.target.value)} placeholder="" required />
                </div>
                <div className="my-3">
                  <span className="fw-bold">Tipo de documento</span>
                  <select className="form-select" name="usuario" onChange={(e) => setTipoDni(e.target.value)} placeholder="" required >
                    <option value="1">DNI</option>
                    <option value="2">LC</option>
                    <option value="3">LE</option>
                    <option value="4">PTE</option>
                  </select>
                </div>
                <div className="my-3">
                  <span className="fw-bold">número de documento</span>
                  <input className="form-control" type="number" name="usuario" onChange={(e) => setDni(e.target.value)} placeholder="" required />
                </div>
                <div className="text-center mt-4">
                  <Button title="Registrarse" onPress={() => handleLogin()} onLoad={onFetch}/>
                </div>
                <p className="text-danger text-center mt-3" dangerouslySetInnerHTML={{__html: messageApi}}></p>
              </form>
              <div className="d-flex justify-content-center" id="opts-login">
                <ul className="login-more p-t-20 mt-3">
                  <li>
                    <span className="text-muted">
                      Ya tiene una cuenta?
                    </span>
                    <Link className="a-link ps-1" to="/iniciar-sesion">Iniciar sesión</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal title="" show={modal.show} hideClose={true} onClose={() => {}}>
        <div className="text-center">
          <h2>Usuario creado con éxito</h2>
          <FontAwesomeIcon icon={faCheck} className="text-success" style={{fontSize: 100}} />
          <div className="mb-3" dangerouslySetInnerHTML={{__html: modal.body}}></div>
          <Link to="/iniciar-sesion"><Button title="Iniciar sesión" onPress={() => {}} /></Link>
        </div>
      </Modal>
    </div>)
}