import React, { useEffect } from "react";
import Api from "../../utils/api";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from "../../store/reducers";
import { setDatos } from "../../store/actions/fichaMedicaActions";
import DatosDelPasajero from "../../components/fichamedica/DatosDelPasajero";
import PadreMadreTutor from "../../components/fichamedica/PadreMadreTutor";
import CasoUrgencia from "../../components/fichamedica/CasoUrgencia";
import DatosMedicos from "../../components/fichamedica/DatosMedicos";
import HistoriaClinica from "../../components/fichamedica/HistoriaClinica";

export default function CargarEditarFichaMedica() {
  const { id = "-1" } = useParams();

  const fichaMedica = useSelector((state: RootState) => state.fichaMedica);
  const dispatch = useDispatch();

  useEffect(() => {
    Api("consumir")
      .post({
        servicio: "fichaMedicaGet",
        param: { pco_pasajero: id, }
      })
      .then((res: any) => {
        const fvencimiento = res.PREF_DATOS[0].F_VEN_DOCUMENTO ? res.PREF_DATOS[0].F_VEN_DOCUMENTO.match(/^(\d+)\/(\d+)\/(\d+)$/) : "";
        let tvencimiento = "";
        if(fvencimiento){
          tvencimiento = `${fvencimiento[3]}-${fvencimiento[2]}-${fvencimiento[1]}`
        }

        const fnacimiento = res.PREF_DATOS[0].F_NACIMIENTO ? res.PREF_DATOS[0].F_NACIMIENTO.match(/^(\d+)\/(\d+)\/(\d+)$/) : "";
        let tnacimiento = "";
        if(fnacimiento){
          tnacimiento = `${fnacimiento[3]}-${fnacimiento[2]}-${fnacimiento[1]}`
        }

        dispatch(setDatos({
          campos: {
            ...res.PREF_DATOS[0],
            F_VEN_DOCUMENTO: tvencimiento,
            F_NACIMIENTO: tnacimiento,
          },
          dietas: res.PREF_DIETAS.map((e: any) => ({ codigo: e.C_DIETA, dato: e.D_DIETA })),
          sexos: res.PREF_SEXOS.map((e: any) => ({ codigo: e.CODIGO, dato: e.DATO })),
          nacionalidades: res.PREF_NACIONALIDADES.map((e: any) => ({ codigo: e.CODIGO, dato: e.DESCRIPCION })),
          parentescos: res.PREF_PARENTESCOS.map((e: any) => ({ codigo: e.CODIGO, dato: e.DATO })),
          provincias: res.PREF_PROVINCIAS.map((e: any) => ({ codigo: e.CODIGO, dato: e.DATO })),
          docuementos: res.PREF_DOCUMENTOS.map((e: any) => ({ codigo: e.CODIGO, dato: e.DATO })),
          steep: 0,
        }));
      })
  }, [id, dispatch]);
  // {fichaMedica.steep === 0 ? <DatosDelPasajero /> : <></>}
  return (
    <div className="container-data">
      <div className="container mt-3 pt-4 pb-1 d-none d-lg-block">
        <ul className="list-unstyled multi-steps">
          <li className={fichaMedica.steep === 0 ? "text-step is-active" : "text-step"}>Datos del Pasajero</li>
          <li className={fichaMedica.steep === 1 ? "text-step is-active" : "text-step"}>Padre, Madre o Tutor	</li>
          <li className={fichaMedica.steep === 2 ? "text-step is-active" : "text-step"}>En caso de urgencia	</li>
          <li className={fichaMedica.steep === 3 ? "text-step is-active" : "text-step"}>Datos Médicos / Cobertura</li>
          <li className={fichaMedica.steep === 4 ? "text-step is-active" : "text-step"}>Historia clínica</li>
        </ul>
      </div>
      <div className={fichaMedica.steep === 0 ? '' : 'd-none'}>
        <DatosDelPasajero />
      </div>
      
      {fichaMedica.steep === 1 ? <PadreMadreTutor /> : <></>}
      {fichaMedica.steep === 2 ? <CasoUrgencia /> : <></>}
      {fichaMedica.steep === 3 ? <DatosMedicos /> : <></>}
      {fichaMedica.steep === 4 ? <HistoriaClinica /> : <></>}
      
      
    </div>
  )
}