import { useEffect, useState } from "react";
import Button from "../components/Button";

export default function NotFound({ message }: { message: string }) {
  const [loginPhoto, setLoginPhoto] = useState();

  useEffect(() => {
    setLoginPhoto(require(`../assets/images/${process.env.REACT_APP_PLATFORM?.toLocaleLowerCase()}/404.png`))
  }, []);

  const handleReloadApp = () => {
    localStorage.clear();
    window.location.href  = '/iniciar-sesion';
  }

  return (
    <div className="bg-login h-100 text-center">
      <h1 className="h1-login-title d-flex justify-content-center mx-auto mb-sm-5 pb-1 pt-3">{process.env.REACT_APP_PLATFORM} - Sucursal Virtual</h1>
      <div className="rounded bg-white py-5 mx-5">
        <h2>Oops! algo salió mal</h2>
      <img src={loginPhoto} alt="404" />
      <div className="my-5">
      <Button title="VOLVER" onPress={() => handleReloadApp()} />
      </div>
      <p className="text-start px-3 text-danger">{message}</p>
      </div>
    </div>
  )
};