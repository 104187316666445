import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store/reducers";
//import { backSteep } from "../../store/actions/fichaMedicaActions";
import Api from "../../utils/api";
import { useParams } from "react-router-dom";
import Button from "../Button";
import IFichaCargaMedica from "../../interfaces/IFichaMedicaCarga";
import { setDatos } from "../../store/actions/fichaMedicaActions";
import OBJtoXML from "../../utils/objToXML";

export default function DatosDelPasajero() {
  const selectSexo = useRef<HTMLSelectElement>(null);
  const selectProvincia = useRef<HTMLSelectElement>(null);

  const fichaMedica = useSelector((state: RootState) => state.fichaMedica);
  const [apiMessage, setApiMessage] = useState("");
  const [isFetching, setIsFetching] = useState(false);
  const [fechaVencimiento, setFechaVencimiento] = useState("");
  const [fechaNacimiento, setFechaNacimiento] = useState("");
  const [sexo, setSexo] = useState("");
  const [telefonoFijoDDN, setTelefonoFijoDDN] = useState("");
  const [telefonoFijo, setTelefonoFijo] = useState("");
  const [telefonoMovilDDN, setTelefonoMovilDDN] = useState("");
  const [telefonoMovil, setTelefonoMovil] = useState("");
  const [nacionalidad, setNacionalidad] = useState("");
  const [mail, setMail] = useState("");
  const [provincia, setProvincia] = useState("");
  const [localidad, setLocalidad] = useState("");
  const [codigoPostal, setCodigoPostal] = useState("");
  const [calle, setCalle] = useState("");

  const { id = "-1" } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    setFechaVencimiento(fichaMedica.campos.F_VEN_DOCUMENTO);
    setFechaNacimiento(fichaMedica.campos.F_NACIMIENTO);
    setSexo(fichaMedica.campos.C_SEXO);
    setTelefonoFijoDDN(fichaMedica.campos.N_TEL_DDN);
    setTelefonoFijo(fichaMedica.campos.N_TEL_NRO);
    setTelefonoMovilDDN(fichaMedica.campos.N_CEL_DDN);
    setTelefonoMovil(fichaMedica.campos.N_CEL_NRO);
    setNacionalidad(fichaMedica.campos.C_NACIONALIDAD);
    setMail(fichaMedica.campos.D_MAIL);
    setProvincia(fichaMedica.campos.C_PROVINCIA);
    setLocalidad(fichaMedica.campos.D_LOCALIDAD);
    setCodigoPostal(fichaMedica.campos.C_POSTAL);
    setCalle(fichaMedica.campos.D_CALLE);    
    if(selectSexo.current){
      selectSexo.current.value = fichaMedica.campos.C_SEXO;
    }

    if(selectProvincia.current){
      selectProvincia.current.value = fichaMedica.campos.C_PROVINCIA;
    }
  }, [fichaMedica]);

  function handleClickValidar() {
    setApiMessage("");
    setIsFetching(true);
    const fvencimiento = fechaVencimiento.match(/^(\d+)-(\d+)-(\d+)$/);
    let tvencimiento = "";
    if (fvencimiento) {
      tvencimiento = `${fvencimiento[3]}/${fvencimiento[2]}/${fvencimiento[1]}`
    }

    const fnacimiento = fechaNacimiento.match(/^(\d+)-(\d+)-(\d+)$/);
    let tnacimiento = "";
    if (fnacimiento) {
      tnacimiento = `${fnacimiento[3]}/${fnacimiento[2]}/${fnacimiento[1]}`
    }

    let obj: IFichaCargaMedica = fichaMedica;
    obj = {
      ...obj,
      campos: {
        ...obj.campos,
        F_VEN_DOCUMENTO: tvencimiento,
        C_NACIONALIDAD: nacionalidad,
        F_NACIMIENTO: tnacimiento,
        C_SEXO: sexo,
        D_MAIL: mail,
        C_PROVINCIA: provincia,
        D_LOCALIDAD: localidad,
        C_POSTAL: codigoPostal,
        D_CALLE: calle,
        N_TEL_DDN: telefonoFijoDDN,
        N_TEL_NRO: telefonoFijo,
        N_CEL_DDN: telefonoMovilDDN,
        N_CEL_NRO: telefonoMovil,
      },
      steep: 1,
    };
    Api("consumir").post({
      servicio: "fichaMedicaSet",
      param: {
        pc_request: OBJtoXML({ FICHA_MEDICA: obj.campos }),
        pco_pasajero: id,
        pn_pagina: 1,
      }
    }).then(res => {
      setIsFetching(false);
      if (res.mensaje.tipo === "E") {
        setApiMessage(res.mensaje.descripcion);
        return;
      }
      dispatch(setDatos(obj));
    })
  }

  return (
    <div className="container mb-5">
      <div className="row">
        <div className="col-md-6 col-12 my-2">
          <span>Apellido:</span>
          <input defaultValue={fichaMedica.campos.D_APELLIDO} className="form-control" type="text" disabled />
        </div>
        <div className="col-md-6 col-12 my-2">
          <span>Nombre:</span>
          <input defaultValue={fichaMedica.campos.D_NOMBRE} className="form-control" type="text" disabled />
        </div>
        <div className="col-md-4 col-12 my-2">
          <span>Tipo de documento:</span>
          <select className="form-select" value={fichaMedica.campos.T_DOCUMENTO} disabled>
            {
              fichaMedica.docuementos.map(campo => <option key={campo.codigo} value={campo.codigo}>{campo.dato}</option>)
            }
          </select>
        </div>
        <div className="col-md-4 col-12 my-2">
          <span>Número de documento:</span>
          <input className="form-control" type="number" defaultValue={fichaMedica.campos.N_DOCUMENTO} disabled />
        </div>
        <div className="col-md-4 col-12 my-2">
          <span>Vencimiento del DNI:</span>
          <input onChange={(e) => setFechaVencimiento(e.target.value)} className="form-control" type="date" defaultValue={fichaMedica.campos.F_VEN_DOCUMENTO} />
        </div>
        <div className="col-md-4 col-12 my-2">
          <span>Nacionalidad:</span>
          <input onChange={(e) => setNacionalidad(e.target.value)} className="form-control" type="text" defaultValue={fichaMedica.campos.C_NACIONALIDAD} />
        </div>
        <div className="col-md-4 col-12 my-2">
          <span>Fecha de Nacimiento:</span>
          <input onChange={(e) => setFechaNacimiento(e.target.value)} className="form-control" type="date" defaultValue={fichaMedica.campos.F_NACIMIENTO} />
        </div>
        <div className="col-md-4 col-12 my-2">
          <span>Sexo:</span>
          <select ref={selectSexo} onChange={(e) => setSexo(e.target.value)} className="form-select" defaultValue={fichaMedica.campos.C_SEXO}>
            {
              fichaMedica.sexos.map(campo => <option key={campo.codigo}  value={campo.codigo}>{campo.dato}</option>)
            }
          </select>
        </div>
        <div className="col-md-8 col-12 my-2">
          <span>Mail:</span>
          <input defaultValue={fichaMedica.campos.D_MAIL} onChange={(e) => setMail(e.target.value)} className="form-control" type="email" />
        </div>
        <div className="col-md-4 col-12 my-2"></div>
        <div className="col-md-4 col-12 my-2">
          <span>Porvincia:</span>
          <select ref={selectProvincia} onChange={(e) => setProvincia(e.target.value)} className="form-select">
            {
              fichaMedica.provincias.map(campo => <option key={campo.codigo} value={campo.codigo}>{campo.dato}</option>)
            }
          </select>
        </div>
        <div className="col-md-4 col-12 my-2">
          <span>Localidad:</span>
          <input defaultValue={fichaMedica.campos.D_LOCALIDAD} onChange={(e) => setLocalidad(e.target.value)} className="form-control" type="text" />
        </div>
        <div className="col-md-4 col-12 my-2">
          <span>Código postal:</span>
          <input defaultValue={fichaMedica.campos.C_POSTAL} onChange={(e) => setCodigoPostal(e.target.value)} className="form-control" type="text" />
        </div>
        <div className="col-12 my-2">
          <span>Calle:</span>
          <input defaultValue={fichaMedica.campos.D_CALLE} onChange={(e) => setCalle(e.target.value)} className="form-control" type="text" />
        </div>
        <div className="col-md-2 col-12 my-2">
          <span>Teléfono Fijo - (DDN):</span>
          <input defaultValue={fichaMedica.campos.N_TEL_DDN} onChange={(e) => setTelefonoFijoDDN(e.target.value)} className="form-control" type="tel" />
        </div>
        <div className="col-md-4 col-12 my-2">
          <span>Teléfono Fijo - (Número):</span>
          <input defaultValue={fichaMedica.campos.N_TEL_NRO} onChange={(e) => setTelefonoFijo(e.target.value)} className="form-control" type="tel" />
        </div>
        <div className="col-md-2 col-12 my-2">
          <span>Teléfono Móvil - (DDN):</span>
          <input defaultValue={fichaMedica.campos.N_CEL_DDN} onChange={(e) => setTelefonoMovilDDN(e.target.value)} className="form-control" type="tel" />
        </div>
        <div className="col-md-4 col-12 my-2">
          <span>Teléfono Móvil - (Número):</span>
          <input defaultValue={fichaMedica.campos.N_CEL_NRO} onChange={(e) => setTelefonoMovil(e.target.value)} className="form-control" type="tel" />
        </div>
      </div>
      <div className="text-center mt-4">
        <Button title="Continuar" onLoad={isFetching} onPress={handleClickValidar} />
      </div>
      {apiMessage ? <div dangerouslySetInnerHTML={{ __html: apiMessage }}></div> : <></>}
    </div>
  )
}