import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store/reducers";
import { setSolicitud } from "../../store/actions/objSolicitudActions";
import { nextSteep, backSteep } from "../../store/actions/solicitudActions";
import Api from "../../utils/api";
import OBJtoXML from "../../utils/objToXML";
import IResponseSolicitud from "../../interfaces/IResponseSolicitud";
import Button from "../Button";
import ModalTyC from "./ModalTyC";

export default function DatosDelPasajero() {
  const [porQuqe, setPorQue] = useState("");

  const [showModalTYC, setShowModalTYC] = useState(false);
  const [apiMessage, setApiMessage] = useState("");
  const [isFetching, setIsFetching] = useState(false);

  const objSolicitud = useSelector((state: RootState) => state.ObjSolicitud);
  const campos = useSelector((state: RootState) => state.campoSolicitud);

  const dispatch = useDispatch();

  function handleClickBack() {
    dispatch(backSteep());
  }

  function handleClickModalTYC() {
    setShowModalTYC(false);
    setApiMessage("");
    const pcRequest: IResponseSolicitud = {
      SOLICITUD: {
        ...objSolicitud.SOLICITUD,
        PORQUE: porQuqe,
      }
    }
    setIsFetching(true);
    Api("consumir")
      .post({
        servicio: "altaSolicitud",
        param: {
          pn_pagina: 6,
          pc_request: OBJtoXML(pcRequest),
        }
      }).then(res => {
        setIsFetching(false);
        if (res.mensaje.tipo === "M") {
          dispatch(setSolicitud(pcRequest));
          dispatch(nextSteep());
          return;
        }
        setApiMessage(res.mensaje.descripcion)
      });
  }

  function handleClickValidar() {
    if (!porQuqe) {
      return;
    }
    setShowModalTYC(true);
  }

  return (
    <div className="container mb-5">
      <div className="row">
        <div className="col-12 my-2">
          <span>¿Por qué nos elegiste?</span>
          <select onChange={(e) => setPorQue(e.target.value)} className="form-select">
            {
              campos.pqnoselegiste.map(campo => <option key={campo.codigo} value={campo.codigo}>{campo.dato}</option>)
            }
          </select>
        </div>
      </div>
      <div className="text-center mt-4">
        <Button secondary title="Volver" onPress={handleClickBack} />
        <Button title="Continuar" onLoad={isFetching} onPress={handleClickValidar} />
      </div>
      {apiMessage ? <div dangerouslySetInnerHTML={{ __html: apiMessage }}></div> : <></>}
      <ModalTyC show={showModalTYC} body={campos.tyc[0].tyc} onAcept={() => handleClickModalTYC()} onClose={() => setShowModalTYC(false)} />
    </div>
  )
}