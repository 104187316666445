function escape(str: any){
  return str.replace(/(?:\r\n|\r|\n)/g, '\\n').replace(/(?:")/g, '\\"').replace(/(?:')/g, "\\'")
}

function OBJtoXML(obj: any) {
  var xml = '';
  for (var prop in obj) {
    xml += obj[prop] instanceof Array ? '' : "<" + prop + ">";
    if (obj[prop] instanceof Array) {
      for (var array in obj[prop]) {
        xml += "<" + prop + ">";
        let newObject = {};
        newObject = obj[prop][array];
        xml += OBJtoXML(newObject);
        xml += "</" + prop + ">";
      }
    } else if (typeof obj[prop] == "object") {
      let newObject = {};
      newObject = obj[prop];
      xml += OBJtoXML(newObject);
    } else {
      xml += escape(obj[prop]);
    }
    xml += obj[prop] instanceof Array ? '' : "</" + prop + ">";
  }
  xml = xml.replace(/<\/?[0-9]{1,}>/g, '');
  return xml
}

export default OBJtoXML