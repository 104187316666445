import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store/reducers";
import { backSteep } from "../../store/actions/fichaMedicaActions";
import Api from "../../utils/api";
import { useParams } from "react-router-dom";
import Button from "../Button";
import IFichaCargaMedica from "../../interfaces/IFichaMedicaCarga";
import { setDatos } from "../../store/actions/fichaMedicaActions";
import OBJtoXML from "../../utils/objToXML";

export default function PadreMadreTutor() {

  const fichaMedica = useSelector((state: RootState) => state.fichaMedica);

  const [apiMessage, setApiMessage] = useState("");
  const [isFetching, setIsFetching] = useState(false);

  const [obraSocial, setObraSocial] = useState("");
  const [afiliado, setAfiliado] = useState("");
  const [medico, setMedico] = useState("");
  const [telefonoFijoDDN, setTelefonoFijoDDN] = useState("");
  const [telefonoFijo, setTelefonoFijo] = useState("");

  const { id = "-1" } = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    setObraSocial(fichaMedica.campos.D_OBRA_SOCIAL);
    setAfiliado(fichaMedica.campos.N_AFILIADO);
    setMedico(fichaMedica.campos.D_MEDICO_CABECERA);
    setTelefonoFijoDDN(fichaMedica.campos.PMT_N_TEL_DDN);
    setTelefonoFijo(fichaMedica.campos.PMT_N_TEL_NRO);

  }, [fichaMedica]);

  function handleClickVolver(){
    dispatch(backSteep());
  }

  function handleClickValidar() {
    setApiMessage("");
    setIsFetching(true);

    let obj: IFichaCargaMedica = fichaMedica;
    obj = {
      ...obj,
      campos: {
        ...obj.campos,
        D_OBRA_SOCIAL: obraSocial,
        N_AFILIADO: afiliado,
        D_MEDICO_CABECERA: medico,
        PMT_N_TEL_DDN: telefonoFijoDDN,
        PMT_N_TEL_NRO: telefonoFijo,
      },
      steep: 4,
    };
    Api("consumir").post({
      servicio: "fichaMedicaSet",
      param: {
        pc_request: OBJtoXML({ FICHA_MEDICA: obj.campos }),
        pco_pasajero: id,
        pn_pagina: 4,
      }
    }).then(res => {
      setIsFetching(false);
      if (res.mensaje.tipo === "E") {
        setApiMessage(res.mensaje.descripcion);
        return;
      }
      dispatch(setDatos(obj));
    })
  }

  return (
    <div className="container mb-5">
      <div className="row d-flex justify-content-center">
        <div className="col-lg-7 col-12 my-2">
          <span>Obra Social o Prepaga:</span>
          <input defaultValue={fichaMedica.campos.D_OBRA_SOCIAL} onChange={(e) => setObraSocial(e.target.value)} className="form-control" type="text" />
        </div>
        <div className="col-lg-7 col-12 my-2">
          <span>Número de afiliado o socio:</span>
          <input defaultValue={fichaMedica.campos.N_AFILIADO} onChange={(e) => setAfiliado(e.target.value)} className="form-control" type="text" />
        </div>
        <div className="col-lg-7 col-12 my-2">
          <span>Médico de cabecera:</span>
          <input defaultValue={fichaMedica.campos.D_MEDICO_CABECERA} onChange={(e) => setMedico(e.target.value)} className="form-control" type="text" />
        </div>
        <div className="col-12"></div>
        <div className="col-lg-3 col-12 my-2">
          <span>Teléfono Fijo - (DDN):</span>
          <input defaultValue={fichaMedica.campos.PMT_N_TEL_DDN} onChange={(e) => setTelefonoFijoDDN(e.target.value)} className="form-control" type="tel" />
        </div>
        <div className="col-lg-4 col-12 my-2">
          <span>Teléfono Fijo - (Número):</span>
          <input defaultValue={fichaMedica.campos.PMT_N_TEL_NRO} onChange={(e) => setTelefonoFijo(e.target.value)} className="form-control" type="tel" />
        </div>
      <div className="row d-flex justify-content center">
        <div className="col-3 my-2"></div>

      </div>
      </div>
      <div className="text-center mt-4">
        <Button title="Volver" secondary onPress={handleClickVolver} />
        <Button title="Continuar" onLoad={isFetching} onPress={handleClickValidar} />
      </div>
      {apiMessage ? <div dangerouslySetInnerHTML={{ __html: apiMessage }}></div> : <></>}
    </div>
  )
}