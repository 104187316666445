import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store/reducers";
import { backSteep } from "../../store/actions/fichaMedicaActions";
import Api from "../../utils/api";
import { useParams } from "react-router-dom";
import Button from "../Button";
import IFichaCargaMedica from "../../interfaces/IFichaMedicaCarga";
import { setDatos } from "../../store/actions/fichaMedicaActions";
import OBJtoXML from "../../utils/objToXML";

export default function CasoUrgencia() {
  const selectParentesco = useRef<HTMLSelectElement>(null);
  const selectParentesco2 = useRef<HTMLSelectElement>(null);

  const fichaMedica = useSelector((state: RootState) => state.fichaMedica);

  const [apiMessage, setApiMessage] = useState("");
  const [isFetching, setIsFetching] = useState(false);

  const [parentesco, setParentesco] = useState("");
  const [nombre, setNombre] = useState("");
  const [apellido, setApellido] = useState("");
  const [telefonoFijoDDN, setTelefonoFijoDDN] = useState("");
  const [telefonoFijo, setTelefonoFijo] = useState("");
  const [telefonoMovilDDN, setTelefonoMovilDDN] = useState("");
  const [telefonoMovil, setTelefonoMovil] = useState("");
  const [mail, setMail] = useState("");

  const [parentesco2, setParentesco2] = useState("");
  const [nombre2, setNombre2] = useState("");
  const [apellido2, setApellido2] = useState("");
  const [telefonoFijoDDN2, setTelefonoFijoDDN2] = useState("");
  const [telefonoFijo2, setTelefonoFijo2] = useState("");
  const [telefonoMovilDDN2, setTelefonoMovilDDN2] = useState("");
  const [telefonoMovil2, setTelefonoMovil2] = useState("");
  const [mail2, setMail2] = useState("");

  const { id = "-1" } = useParams();

  //const objSolicitud = useSelector((state: RootState) => state.ObjSolicitud);

  const dispatch = useDispatch();

  useEffect(() => {
    setParentesco(fichaMedica.campos.CE1_T_PARENTESCO);
    setNombre(fichaMedica.campos.CE1_D_NOMBRE);
    setApellido(fichaMedica.campos.CE1_D_APELLIDO);
    setTelefonoFijoDDN(fichaMedica.campos.CE1_N_TEL_DDN);
    setTelefonoFijo(fichaMedica.campos.CE1_N_TEL_NRO);
    setTelefonoMovilDDN(fichaMedica.campos.CE1_N_CEL_DDN);
    setTelefonoMovil(fichaMedica.campos.CE1_N_CEL_NRO);
    setMail(fichaMedica.campos.CE1_D_EMAIL);

    setParentesco2(fichaMedica.campos.CE2_T_PARENTESCO);
    setNombre2(fichaMedica.campos.CE2_D_NOMBRE);
    setApellido2(fichaMedica.campos.CE2_D_APELLIDO);
    setTelefonoFijoDDN2(fichaMedica.campos.CE2_N_TEL_DDN);
    setTelefonoFijo2(fichaMedica.campos.CE2_N_TEL_NRO);
    setTelefonoMovilDDN2(fichaMedica.campos.CE2_N_CEL_DDN);
    setTelefonoMovil2(fichaMedica.campos.CE2_N_CEL_NRO);
    setMail2(fichaMedica.campos.CE2_D_EMAIL);

    if(selectParentesco.current){
      selectParentesco.current.value = fichaMedica.campos.CE1_T_PARENTESCO;
    }

    if(selectParentesco2.current){
      selectParentesco2.current.value = fichaMedica.campos.CE2_T_PARENTESCO;
    }
  }, [fichaMedica]);

  function handleClickVolver(){
    dispatch(backSteep());
  }

  function handleClickValidar() {
    setApiMessage("");
    setIsFetching(true);

    let obj: IFichaCargaMedica = fichaMedica;
    obj = {
      ...obj,
      campos: {
        ...obj.campos,
        CE1_T_PARENTESCO: parentesco,
        CE1_D_NOMBRE: nombre,
        CE1_D_APELLIDO: apellido,
        CE1_D_EMAIL: mail,
        CE1_N_TEL_DDN: telefonoFijoDDN,
        CE1_N_TEL_NRO: telefonoFijo,
        CE1_N_CEL_DDN: telefonoMovilDDN,
        CE1_N_CEL_NRO: telefonoMovil,
        CE2_T_PARENTESCO: parentesco2,
        CE2_D_NOMBRE: nombre2,
        CE2_D_APELLIDO: apellido2,
        CE2_D_EMAIL: mail2,
        CE2_N_TEL_DDN: telefonoFijoDDN2,
        CE2_N_TEL_NRO: telefonoFijo2,
        CE2_N_CEL_DDN: telefonoMovilDDN2,
        CE2_N_CEL_NRO: telefonoMovil2,
      },
      steep: 3,
    };
    Api("consumir").post({
      servicio: "fichaMedicaSet",
      param: {
        pc_request: OBJtoXML({ FICHA_MEDICA: obj.campos }),
        pco_pasajero: id,
        pn_pagina: 3,
      }
    }).then(res => {
      setIsFetching(false);
      if (res.mensaje.tipo === "E") {
        setApiMessage(res.mensaje.descripcion);
        return;
      }
      dispatch(setDatos(obj));
    })
  }

  return (
    <div className="container mb-5">
      <div className="row">
      <div className="col-md-4 col-12 my-2">
          <span>Parentezco:</span>
          <select ref={selectParentesco} onChange={(e) => setParentesco(e.target.value)} className="form-select" defaultValue={fichaMedica.campos.CE1_T_PARENTESCO}>
            {
              fichaMedica.parentescos.map(campo => <option key={campo.codigo}  value={campo.codigo}>{campo.dato}</option>)
            }
          </select>
        </div>
        <div className="col-md-4 col-12 my-2">
          <span>Apellido:</span>
          <input defaultValue={fichaMedica.campos.CE1_D_APELLIDO} onChange={(e) => setApellido(e.target.value)} className="form-control" type="text" />
        </div>
        <div className="col-md-4 col-12 my-2">
          <span>Nombre:</span>
          <input defaultValue={fichaMedica.campos.CE1_D_NOMBRE} onChange={(e) => setNombre(e.target.value)} className="form-control" type="text" />
        </div>
        <div className="col-md-2 col-12 my-2">
          <span>Teléfono Fijo - (DDN):</span>
          <input defaultValue={fichaMedica.campos.CE1_N_TEL_DDN} onChange={(e) => setTelefonoFijoDDN(e.target.value)} className="form-control" type="tel" />
        </div>
        <div className="col-md-4 col-12 my-2">
          <span>Teléfono Fijo - (Número):</span>
          <input defaultValue={fichaMedica.campos.CE1_N_TEL_NRO} onChange={(e) => setTelefonoFijo(e.target.value)} className="form-control" type="tel" />
        </div>
        <div className="col-md-2 col-12 my-2">
          <span>Teléfono Móvil - (DDN):</span>
          <input defaultValue={fichaMedica.campos.CE1_N_CEL_DDN} onChange={(e) => setTelefonoMovilDDN(e.target.value)} className="form-control" type="tel" />
        </div>
        <div className="col-md-4 col-12 my-2">
          <span>Teléfono Móvil - (Número):</span>
          <input defaultValue={fichaMedica.campos.CE1_N_CEL_NRO} onChange={(e) => setTelefonoMovil(e.target.value)} className="form-control" type="tel" />
        </div>
        <div className="col-md-8 col-12 my-2">
          <span>Mail:</span>
          <input defaultValue={fichaMedica.campos.CE1_D_EMAIL} onChange={(e) => setMail(e.target.value)} className="form-control" type="email" />
        </div>
      </div>
      <hr />
      <div className="row">
      <div className="col-md-4 col-12 my-2">
          <span>Parentezco:</span>
          <select ref={selectParentesco} onChange={(e) => setParentesco2(e.target.value)} className="form-select" defaultValue={fichaMedica.campos.CE2_T_PARENTESCO}>
            {
              fichaMedica.parentescos.map(campo => <option key={campo.codigo}  value={campo.codigo}>{campo.dato}</option>)
            }
          </select>
        </div>
        <div className="col-md-4 col-12 my-2">
          <span>Apellido:</span>
          <input defaultValue={fichaMedica.campos.CE2_D_APELLIDO} onChange={(e) => setApellido2(e.target.value)} className="form-control" type="text" />
        </div>
        <div className="col-md-4 col-12 my-2">
          <span>Nombre:</span>
          <input defaultValue={fichaMedica.campos.CE2_D_NOMBRE} onChange={(e) => setNombre2(e.target.value)} className="form-control" type="text" />
        </div>
        <div className="col-md-2 col-12 my-2">
          <span>Teléfono Fijo - (DDN):</span>
          <input defaultValue={fichaMedica.campos.CE2_N_TEL_DDN} onChange={(e) => setTelefonoFijoDDN2(e.target.value)} className="form-control" type="tel" />
        </div>
        <div className="col-md-4 col-12 my-2">
          <span>Teléfono Fijo - (Número):</span>
          <input defaultValue={fichaMedica.campos.CE2_N_TEL_NRO} onChange={(e) => setTelefonoFijo2(e.target.value)} className="form-control" type="tel" />
        </div>
        <div className="col-md-2 col-12 my-2">
          <span>Teléfono Móvil - (DDN):</span>
          <input defaultValue={fichaMedica.campos.CE2_N_CEL_DDN} onChange={(e) => setTelefonoMovilDDN2(e.target.value)} className="form-control" type="tel" />
        </div>
        <div className="col-md-4 col-12 my-2">
          <span>Teléfono Móvil - (Número):</span>
          <input defaultValue={fichaMedica.campos.CE2_N_CEL_NRO} onChange={(e) => setTelefonoMovil2(e.target.value)} className="form-control" type="tel" />
        </div>
        <div className="col-md-8 col-12 my-2">
          <span>Mail:</span>
          <input defaultValue={fichaMedica.campos.CE2_D_EMAIL} onChange={(e) => setMail2(e.target.value)} className="form-control" type="email" />
        </div>
      </div>
      <div className="text-center mt-4">
        <Button title="Volver" secondary onPress={handleClickVolver} />
        <Button title="Continuar" onLoad={isFetching} onPress={handleClickValidar} />
      </div>
      {apiMessage ? <div dangerouslySetInnerHTML={{ __html: apiMessage }}></div> : <></>}
    </div>
  )
}