import React from "react";
import NotFound from './NotFound';

export default class ErrorBoundary extends React.Component<{}, { hasError: boolean, error: string, errorInfo: any }> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false, error: '', errorInfo: null };
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error: error.stack.toString() };
  }

  componentDidCatch(error: any, errorInfo: any) {
    // You can also log the error to an error reporting service
    return { hasError: true, error: error.stack.toString() };
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <NotFound message={this.state.error} />
    }
    return this.props.children; 
  }
}