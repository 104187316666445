import React, { useEffect, useState } from 'react';
import { useDispatch } from "react-redux";
import { setRoute } from "../../store/actions/routeActions";
import { SkeletonTable } from '../../components/Skeleton';
import Api from "../../utils/api";
import Button from '../../components/Button';
import { faFilePdf } from '@fortawesome/free-regular-svg-icons'
import DownloadFile from '../../utils/downloadFile';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

export default function TusSolicitudes() {
  const [onLoad, setOnLoad] = useState(true);
  const [solicitudes, setSolicitudes] = useState<Array<any>>([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setRoute({
      section: 'solicitudes',
      sub: 'tus-solicitudes',
      cp: '',
    }));
  }, [dispatch]);

  useEffect(() => {
    Api("consumir")
      .post({
        servicio: "listaSolicitudes",
      }).then(res => {
        setOnLoad(false);
        setSolicitudes(res.PCURSOR.map((e: any) => ({ ...e, onLoad: false })));
      })
  }, []);

  const getPdf = (idSolicitud: string, claveEmision: string) => {
    const solicitud = solicitudes.findIndex(e => e.N_ID_SOL_PASAJERO === idSolicitud);
    let _solicitudes = [...solicitudes];
    _solicitudes[solicitud].onLoad = true;
    setSolicitudes(_solicitudes);

    Api("birt")
      .post({
        reporte: "SV_cuota.rptdesign",
        param: {
          idSolicitud,
          claveEmision,
        },
      }, "text/plain")
      .then((res: string) => {
        let _solicitudes = [...solicitudes];
        _solicitudes[solicitud].onLoad = false;
        setSolicitudes(_solicitudes);
        DownloadFile(res, `solicitud nro ${idSolicitud}`);
      });
  }

  const showSolicitud = (idSolicitud: string) => {
    const solicitud = solicitudes.findIndex(e => e.N_ID_SOL_PASAJERO === idSolicitud);
    let _solicitudes = [...solicitudes];
    _solicitudes[solicitud].show = !_solicitudes[solicitud].show;
    setSolicitudes(_solicitudes);
  }

  return (
    <div className="container-data">
      <div className="table-responsive">
        {
          onLoad
            ? <SkeletonTable />
            : <section>
              <div className="d-grid-header-all py-2">
                <span>Fecha de carga</span>
                <span>Año</span>
                <span>Destino</span>
                <span>Persona que viaja</span>
                <span>Nro solicitud</span>
                <span>Apellido y nombre</span>
                <span>Código Pasajero</span>
                <span></span>
              </div>
              <div className="d-grid-title">
                     <span>Año</span>
                     <span>Destino</span>
                     <span>Fecha</span>
                     <span></span>
              </div>
              {
                solicitudes.map(row =>
                  <div key={row.N_ID_SOL_PASAJERO} className="d-grid-solicitudes">
                    <div onClick={() => showSolicitud(row.N_ID_SOL_PASAJERO)} className="d-grid-title cursor-pointer">
                     <span>{row.TVIAJE}</span>
                     <span>{row.DESTINO}</span>
                     <span>{row.F_CARGA}</span>
                     <span><FontAwesomeIcon className={row.show ? 'r-90' : ''} icon={faChevronDown} /></span>
                    </div>
                    <div className={`d-grid-header ${row.show ? 'show' : ''}`}>
                      <div>Fecha de carga:</div>
                      <div>Año:</div>
                      <div>Destino:</div>
                      <div>Persona que viaja:</div>
                      <div>Nro solicitud:</div>
                      <div>Apellido y nombre:</div>
                      <div>Código Pasajero:</div>
                      <div>Imprimir:</div>
                    </div>
                    <div className={`d-grid-body ${row.show ? 'show' : ''}`}>
                      <div>{row.F_CARGA}</div>
                      <div>{row.TVIAJE}</div>
                      <div>{row.DESTINO}</div>
                      <div>{row.D_PERSONA_VIAJA}</div>
                      <div>{row.N_ID_SOL_PASAJERO}</div>
                      <div>{row.D_APEYNOM}</div>
                      <div>{row.CO_PASAJERO ? row.CO_PASAJERO : '-'}</div>
                      <div><Button title="Descargar" onLoad={row.onLoad} onPress={() => getPdf(row.N_ID_SOL_PASAJERO, row.CLAVE_EMISION)} icon={faFilePdf} /></div>
                    </div>
                  </div>
                )
              }
              {
                !onLoad && !solicitudes.length 
                ? <p className='text-center'>No se encontraron solicitudes.</p>
                : <></>
              }
            </section>
        }
      </div>
    </div>
  );
}