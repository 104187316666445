import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Api from "../../utils/api";
import { setRoute } from "../../store/actions/routeActions";
import { useDispatch } from 'react-redux';
import { SkeletonTable } from "../../components/Skeleton";

interface IDatoPasajero {
  D_GRUPO: string;
  D_ID: string;
  D_TITULO: string;
  D_VALOR: string;
  N_ID: string;
  N_ORDEN: number;
}

interface IResponse {
  PD_ERROR: string;
  PM_ERROR: string;
  PREF_DATOS: IDatoPasajero[];
  mensaje: any;
}

export default function CuotaCero() {
  const [datosPasajero, setDatosPasajero] = useState<Array<IDatoPasajero>>([])
  const [onLoad, setOnLoad] = useState(true);

  const { id = "-1" } = useParams();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setRoute({
      section: 'pasajero',
      sub: 'cuota-cero',
      cp: id,
    }));
  }, [dispatch, id]);

  useEffect(() => {
    setDatosPasajero([]);
    Api("consumir").post({
      servicio: "paxCuotaCero",
      param: {
        pco_pasajero: id,
      },
    }).then((res: IResponse) => {
      setOnLoad(false);
      setDatosPasajero(res.PREF_DATOS.sort((a, b) => Number(a.N_ORDEN) > Number(b.N_ORDEN) ? 1 : -1));
    });
  }, [id]);

  return (
    <div className="table-responsive container-data pt-5">
      {
        onLoad
          ? <SkeletonTable />
          : <table className="table table-striped">
            <thead>
              <tr className="th-platform"><th colSpan={2}>Cuota cero</th></tr>
            </thead>
            <tbody>
              {
                datosPasajero.map((dato: IDatoPasajero) => (
                  <tr key={dato.D_GRUPO + dato.N_ORDEN}>
                    <th>{dato.D_TITULO}</th>
                    <td>{dato.D_VALOR}</td>
                  </tr>
                ))
              }
            </tbody>
          </table>
      }
    </div>)
}