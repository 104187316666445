import React, { useEffect, useState } from "react";
import Api from "../../utils/api";
import { useParams, useNavigate } from "react-router-dom";
import IDatoPasajero from "../../interfaces/IDatoPasajero";
import Button from "../../components/Button";
import { setRoute } from "../../store/actions/routeActions";
import { useDispatch, useSelector } from 'react-redux';
import { SkeletonTable, SkeletonAlert } from "../../components/Skeleton";
import DownloadFile from "../../utils/downloadFile";
import { RootState } from "../../store/reducers";
import IPrefPasajeros from "../../interfaces/IPrefPasajeros";

export default function FichaMedica() {
  const [datos, setDatos] = useState<Array<IDatoPasajero>>([]);
  const [imprimir, setImprimir] = useState(false);
  const [isLoadImprimir, setIsLoadImprimir] = useState(false);
  const [alta, setAlta] = useState(false);
  const { id = "-1" } = useParams();
  const [onLoad, setOnLoad] = useState(true);
  const [codigoFichaMedica, setCodigofichaMedica] = useState("");
  const [nombre, setNombre] = useState<string>("");

  const navigate = useNavigate();

  const pasajeros = useSelector((e: RootState) => e.pasajeros);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setRoute({
      section: 'pasajero',
      sub: 'ficha-medica',
      cp: id,
    }));
  setNombre(pasajeros.pasajeros.find((p: IPrefPasajeros) => p.CO_PASAJERO === id)?.D_APEYNOM || "");
  }, [dispatch, id, pasajeros.pasajeros]);

  useEffect(() => {
    setOnLoad(true);
    Api("consumir")
      .post({
        servicio: "fichaMedicaInicio",
        param: {
          pco_pasajero: id,
        },
      })
      .then(res => {
        setOnLoad(false);
        setCodigofichaMedica(res.PREF_DATOS.find((e: any) => e.D_TITULO === "Código Ficha Médica")?.D_VALOR || "");
        setImprimir(res.PM_IMPRIMIR === "S");
        setAlta(res.PM_ALTA === "S");
        setDatos(res.PREF_DATOS
          .map((e: IDatoPasajero) => ({ ...e, N_ORDEN: Number(e.N_ORDEN) }))
          .sort((a: IDatoPasajero, b: IDatoPasajero) => Number(a.N_ORDEN) > Number(b.N_ORDEN) ? 1 : -1));
      });
  }, [id]);

  function handleClickCargarEditar(){
    navigate(`/pasajero/${id}/${nombre.replace("*", "").replaceAll(" ", "-")}/ficha-medica/cargar-editar`)
  }

  function handleClickImprimir(){
    setIsLoadImprimir(true);
    Api("birt")
    .post({
      reporte: "SV_ficha_medica.rptdesign",
      param: {
        prm_n_id_ficha_medica: codigoFichaMedica,
      },
    }, "text/plain")
    .then((res: string) => {
      DownloadFile(res, `${id} - Ficha Medica ${codigoFichaMedica}`);
      setIsLoadImprimir(false);
    });
  }

  return (
    <div className="container-data table-responsive">
      <div className="container">
        {
          onLoad
            ? <>
              <SkeletonTable />
              <div className="mt-5">
                <SkeletonAlert />
              </div>
            </>
            : <>
              <table className="table table-striped">
                <thead>
                  <tr className="th-platform"><th colSpan={2}>Ficha médica</th></tr>
                </thead>
                <tbody>
                  {
                    datos.map((dato: IDatoPasajero) => (
                      <tr key={dato.D_GRUPO + dato.N_ORDEN}>
                        <th>{dato.D_TITULO}</th>
                        <td>{dato.D_VALOR}</td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
              {
                alta
                  ? <div className="alert alert-secondary">
                    <div>Con esta opción podrá incorporar o modificar su FICHA MEDICA. La misma deberá ser presentada al organizador. La cual deberá contar con firma del padre, madre o tutor y con el sello/firma de un médico matriculado. La misma tiene carácter de declaración jurada.</div>
                    <div className="text-center"><Button title="Cargar / Editar Ficha Medica" onPress={() => handleClickCargarEditar()} /></div>
                  </div>
                  : <></>
              }
              {
                imprimir
                  ? <div className="alert alert-secondary">
                    <div>Con esta opción podrá generar su FICHA MEDICA en un formato PDF. Tenga en cuenta que si el profesional realiza algún cambio en la misma, deberá actualizar dichos cambios en su ficha electrónica. Ya que la información, se enviara automáticamente al destino para ser incorporada en la pulsera que la empresa otorgará al iniciar el tour.</div>
                    <div className="text-center"><Button title="Imprimir" onLoad={isLoadImprimir} onPress={() => handleClickImprimir()} /></div>
                  </div>
                  : <></>
              }
            </>
        }
      </div>
    </div>)
}