import React, { useState } from 'react'
import Api from '../../utils/api';
import Modal from '../Modal';

interface ITarjeta {
  CODIGO: string,
  PEDIR_TARJETA: boolean,
  TARJETA: string,
}

interface ICuota {
  CODIGO: string,
  CUOTAS: string,
  DESCRIPCION: string,
  IMP_ORIGINAL: number,
}

interface IProps {
  onClose: Function,
  show: boolean,
  totalPagoOnline: number,
  cuotas: ICuota[],
  tarjetas: ITarjeta[],
  pagoToken: string,
}

export default function ModalPagoTarjeta({ onClose, show, totalPagoOnline, tarjetas, cuotas, pagoToken }: IProps) {
  const [tarjeta, setTarjeta] = useState<ITarjeta | null>(null);
  const [cuota, setCuota] = useState<ICuota | null>(null);

  const [isLoad, setIsLoad] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [digits, setDigits] = useState<string>("");

  const handleChangeTarjeta = async (codigo: string) => {
    setMessage("");
    setCuota(null);
    setDigits("");

    const tarjetaSelected = tarjetas.find(e => e.CODIGO === codigo);
    if (tarjetaSelected?.TARJETA) {
      setTarjeta(tarjetaSelected);
      return;
    }
    setTarjeta(null);
  }

  const handleChangeCuota = async (codigo: string) => {
    setMessage("");

    const cuotaSelected = cuotas.find(e => e.CUOTAS === codigo);
    if (cuotaSelected?.CUOTAS) {
      setCuota(cuotaSelected);
      return;
    }
    setCuota(null);
  }

  const handleClickAceptar = () => {
    if (!(tarjeta && cuota)) {
      setMessage("Por favor, completá todos los campos");
      return;
    }

    setIsLoad(true);

    Api("pagoDecidir")
      .post({
        cuotas: cuota.CUOTAS,
        inicio_tarjeta: digits,
        pagoToken,
        tarjeta: tarjeta.CODIGO,
      }).then(res => {
        if (res.url) {
          window.location.href = res.url;
          onClose();
          return;
        }
        setMessage(res.error);
      }).finally(() => {
        setIsLoad(false);
      })
  }

  return (
    <Modal title="Pago con tarjeta" onClose={() => onClose()} show={show} btnAceptar="Realizar pago" onAceptar={() => handleClickAceptar()} onLoadAceptar={isLoad}>
      <h5 className="text-center my-3">Importe neto a pagar</h5>
      <div>
        <p className="text-center">${new Intl.NumberFormat('es-AR', { minimumFractionDigits: 2 }).format(totalPagoOnline)}</p>
      </div>
      <form>
        <select className="form-select my-2" onChange={(e) => handleChangeTarjeta(e.target.value)}>
          <option value="0">-Tarjeta-</option>
          {
            tarjetas.map((e: ITarjeta) =>
              <option key={e.CODIGO} value={e.CODIGO}>{e.TARJETA}</option>
            )
          }
        </select>
        <select className="form-select my-2" onChange={(e) => handleChangeCuota(e.target.value)}>
          <option value="0">-Cantidad de Cuotas-</option>
          {
            cuotas.filter(e => e.CODIGO === tarjeta?.CODIGO).map((e: ICuota) =>
              <option key={e.CODIGO + e.CUOTAS} value={e.CUOTAS}>{e.CUOTAS}</option>
            )
          }
        </select>
        {
          tarjeta?.PEDIR_TARJETA
            ? <input
              className="form-control"
              type="text"
              defaultValue={digits}
              maxLength={6}
              placeholder="Ingrese los 6 primeros digitos de la tarjeta"
              onChange={(e) => setDigits(e.target.value)}
            />
            : <></>
        }
      </form>
      {
        cuota
          ? <h5 className="text-danger text-center">{cuota.DESCRIPCION}</h5>
          : <></>
      }
      <p className="text-center text-danger" dangerouslySetInnerHTML={{ __html: message }}></p>
    </Modal>
  )
}
