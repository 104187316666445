import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import IServicioIndividual from '../../interfaces/IServicioIndividual';
import Api from '../../utils/api';
import ModalPagoOnline from './ModalPagoOnline';
import ModalPagoTarjeta from './ModalPagoTarjeta';
import Button from '../Button';
import DownloadFile from '../../utils/downloadFile';

import './ServiciosPreViaje.css';

interface ITarjeta {
  CODIGO: string,
  PEDIR_TARJETA: boolean,
  TARJETA: string,
}

interface ICuota {
  CODIGO: string,
  CUOTAS: string,
  DESCRIPCION: string,
  IMP_ORIGINAL: number,
}

interface IProps {
  servicios: IServicioIndividual[],
  hasMercadoPago: boolean,
  hasPagoTarjeta: boolean,
}


interface IPropsRow {
  servicio: IServicioIndividual,
  i: number,
  togglePagoOnline: Function
}

function Row({servicio, i, togglePagoOnline}: IPropsRow) {
  const [print, setPrint] = useState<boolean>(false);
  const { id = "-1" } = useParams();

  const handleClickPrint = () => {
    setPrint(true);
    Api("birt")
      .post({
        param: {
          prm_co_pasajero: id,
          prm_nu_talon: servicio.NU_TALON,
        },
        reporte: "SV_cupon_SI.rptdesign"
      }, "text/plain")
      .then((res: string) => {
        DownloadFile(res, `${id} - Talon ${servicio.NU_TALON}`);
      })
      .finally(() => {
        setPrint(false);
      });
  }

  return (
    <div className="grid-row">
      <span title="Servicio individual" className="th-title">{i}</span>
      <span title="Fecha vencimiento">{servicio.F_VENCIMIENTO}</span>
      <span title="Importe">{servicio.MONEDA}{servicio.D_IMPORTE}</span>
      <span title="Pago online">
        <input type="checkbox" onChange={e => togglePagoOnline(servicio, e.target.checked)} />
      </span>
      <span title="Tipo">{servicio.D_TIPO}</span>
      <span title="Lista de servicios">{servicio.D_LISTA_SERVICIOS}</span>
      <span title="Imprimir">{ servicio.BOT_IMPRIMIR ? <Button title="Imprimir" onLoad={print} onPress={() => handleClickPrint()} /> : <></>}</span>
    </div>);
}

export default function ServiciosPreViaje({ servicios = [], hasMercadoPago, hasPagoTarjeta }: IProps) {
  const [msgError, setMgError] = useState<string>("");

  const [pagoOnline, setPagoOnline] = useState<Array<IServicioIndividual>>([]);
  const [totalPagoOnline, setTotalPagoOnline] = useState<number>(0);

  const [showModalPago, setShowModalPago] = useState(false);
  const [showModalPagoTarjeta, setShowModalPagoTarjeta] = useState(false);

  const [cuotas, setCuotas] = useState<Array<ICuota>>([]);
  const [tarjetas, setTarjetas] = useState<Array<ITarjeta>>([]);
  const [pagoToken, setPagoToken] = useState<string>("");

  const [onLoadPagoTarjeta, setOnLoadPagoTarjeta] = useState(false);
  const { id = "-1" } = useParams();

  const togglePagoOnline = (servicio: IServicioIndividual, state: boolean) => {
    if (state) {
      setPagoOnline([...pagoOnline, servicio]);
      return;
    }
    setPagoOnline(pagoOnline.filter((e: IServicioIndividual) => e.NU_TALON !== servicio.NU_TALON))
  }

  useEffect(() => {
    setMgError("");
    setTotalPagoOnline(pagoOnline.reduce((acc: number, e: IServicioIndividual) => acc + e.IMP_PAGAR, 0));
  }, [pagoOnline]);

  const handleClickPagoTarjeta = () => {
    setOnLoadPagoTarjeta(true);
    let ptalones = pagoOnline.reduce((acc: string, e: IServicioIndividual) => acc + `${e.NU_TALON},`, '')
    ptalones = ptalones.slice(0, -1);
    Api("consumir")
      .post({
        param: {
          pco_pasajero: id,
          pimporte: totalPagoOnline,
          ptalones,
        },
        servicio: "pagarDecidirInicio"
      }).then(res => {
        setOnLoadPagoTarjeta(false);
        setShowModalPago(false);
        if (!res.PPAGOTOKEN) {
          return;
        }

        setPagoToken(res.PPAGOTOKEN);

        setTarjetas(res.PRM_LIS_TARJETAS.map((e: any) => ({
          CODIGO: e.CODIGO,
          PEDIR_TARJETA: e.PEDIR_TARJETA !== "N",
          TARJETA: e.TARJETA,
        })));

        setCuotas(res.PRM_LIS_CUOTAS.map((e: any) => ({
          CODIGO: e.CODIGO,
          CUOTAS: e.CUOTAS,
          DESCRIPCION: e.DESCRIPCION,
          IMP_ORIGINAL: Number(e.IMP_ORIGINAL),
        })));

        setShowModalPagoTarjeta(true);
      })
  }

  const handleClickPagoOnline = () => {
    if (!pagoOnline.length) {
      setMgError("Por favor, selecciona alguna cuota");
      return;
    }
    setShowModalPago(true);
  }

  return (
    <div className="servicios-previaje">
      <h4>Servicios Pre-Viaje:</h4>
      <div className="d-grid grid-container">
        <div className="th-platform">
          <span>Fecha vencimiento</span>
          <span>Importe</span>
          <span>Pago online</span>
          <span>Tipo</span>
          <span>Lista de servicios</span>
          <span></span>
        </div>
        <div className="td-platform">
          {
            servicios.map((servicio, i) => <Row key={servicio.NU_TALON} servicio={servicio} i={i + 1} togglePagoOnline={(a: IServicioIndividual,b: boolean) => togglePagoOnline(a,b)} />)
          }
        </div>
      </div>
      <div className="my-3 alert alert-warning text-center h5">
        Importe neto a pagar: <span>${new Intl.NumberFormat('es-AR', { minimumFractionDigits: 2 }).format(totalPagoOnline)}</span>
      </div>
      <span className="btn btn-platform w-100" onClick={() => handleClickPagoOnline()}>Pago online</span>
      <h5 className="mt-2 text-danger text-center">{msgError}</h5>
      <ModalPagoOnline
        show={showModalPago}
        onClose={() => setShowModalPago(false)}
        hasMercadoPago={hasMercadoPago}
        cuotas={pagoOnline}
        hasPagoTarjeta={hasPagoTarjeta}
        onLoadPagoTarjeta={onLoadPagoTarjeta}
        clickPagoTarjeta={() => handleClickPagoTarjeta()}
      />
      <ModalPagoTarjeta
        totalPagoOnline={totalPagoOnline}
        onClose={() => setShowModalPagoTarjeta(false)}
        show={showModalPagoTarjeta}
        pagoToken={pagoToken}
        tarjetas={tarjetas}
        cuotas={cuotas}
      />
    </div>
  )
}
