import React, { useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from "../../store/reducers";
import { setRoute } from "../../store/actions/routeActions";

export default function TusDatos() {
  const auth = useSelector((state: RootState) => state.auth);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setRoute({
      section: 'usuario',
      sub: 'tus-datos',
      cp: '',
    }));
  }, [dispatch]);

  return (
    <div className="container-data">
      <div className="container table-responsive">
        <table className="table table-striped">
          <thead>
            <tr className="th-platform"><th colSpan={2}>Datos del usuario</th></tr>
          </thead>
          <tbody>
            <tr>
              <th>Nombre</th>
              <td>{auth.nombre}</td>
            </tr>
            <tr>
              <th>Apellido</th>
              <td>{auth.apellido}</td>
            </tr>
            <tr>
              <th>Email</th>
              <td>{auth.email}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>)
}