import { Outlet, Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../store/reducers";
import IPrefPasajeros from "../interfaces/IPrefPasajeros";
import React, { useEffect, useState } from "react";

export default function PanelPasajero() {
  const [solapas, setSolapas] = useState<Array<string>>([]);
  const [promoMensaje, setPromoMensaje] = useState("");
  const [pdf, setPdf] = useState("");
  let { id, nombre } = useParams();
  
  const route = useSelector((state: RootState) => state.route);

  const listPasajeros: IPrefPasajeros[] = useSelector((state: RootState) => state.pasajeros.pasajeros);
  useEffect(() => {
    const pasajero = listPasajeros.find(e => e.CO_PASAJERO === id);
    if(!pasajero){
      return;
    }
    setPromoMensaje(pasajero.PROMO_MENSAJE);
    setSolapas(pasajero.SOLAPAS);
    setPdf(pasajero.D_PDF_TIPS);
  }, [id, listPasajeros]);
  return (
    <div>
      <h3 className="color-platform mb-5">Información para tu viaje</h3>
      <nav className="subtitle-dashboard d-none d-xl-block">
        {
          solapas.find(e => e === "Datos")
            ? <Link className={route.sub === "datos-del-pasajero" ? "active" : ""} to={`/pasajero/${id}/${nombre}/datos-del-pasajero`}>datos del pasajero</Link>
            : <></>
        }
        {
          solapas.find(e => e === "CuotasPagos")
            ? <Link className={route.sub === "cuotas-y-pago" ? "active" : ""} to={`/pasajero/${id}/${nombre}/cuotas`}>cuotas y pago</Link>
            : <></>
        }
        {
          solapas.find(e => e === "Grupos")
            ? <Link className={route.sub === "grupo-de-viaje" ? "active" : ""} to={`/pasajero/${id}/${nombre}/grupo-de-viaje`}>Grupo de viaje</Link>
            : <></>
        }
        {
          solapas.find(e => e === "CuotaCero")
            ? <Link className={route.sub === "cuota-cero" ? "active" : ""} to={`/pasajero/${id}/${nombre}/cuota-cero`}>Cuota cero</Link>
            : <></>
        }
        {
          solapas.find(e => e === "FichaMedica")
            ? <Link className={route.sub === "ficha-medica" ? "active" : ""} to={`/pasajero/${id}/${nombre}/ficha-medica`}>ficha medica</Link>
            : <></>
        }
        {
          solapas.find(e => e === "ServicioIndividual")
            ? <Link className={route.sub === "servicios-individuales" ? "active" : ""} to={`/pasajero/${id}/${nombre}/servicios-individuales`}>Servicios individuales</Link>
            : <></>
        }
        {
          solapas.find(e => e === "Salida")
            ? <Link className={route.sub === "lugar-horario-salida" ? "active" : ""} to={`/pasajero/${id}/${nombre}/lugar-horario-salida`}>Lugar y horario de salida</Link>
            : <></>
        }
        {
          solapas.find(e => e === "TipsParaTuViaje")
          ? <a className={route.sub === "tips-para-tu-viaje" ? "active" : ""} target="_blank" rel="noopener noreferrer" href={pdf}>Tips para tu viaje</a>
          : <></>
        }
      </nav>
      <Outlet />
      <div className="container-data mt-3">
        <h4 className="text-center color-platform">La formula de la diversion</h4>
      <div className="alert alert-secondary" dangerouslySetInnerHTML={{ __html: `<div>${promoMensaje}</div>` }} />
      </div>
    </div>)
}