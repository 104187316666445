import Button from "../Button";
import Visivility from "../../utils/visitility";
import { useRef, useState } from "react";

export default function ModalTyC({show = false, body = "", onClose = () => {}, onAcept = () => {}}: {show: boolean, body: string, onClose: Function, onAcept: Function}) {
  const spanElement = useRef<HTMLElement>(null);
  const [apiMessage, setApiMessage] = useState("");
  const [isRead, setIsRead] = useState<boolean>(false);

  function handleClickAcept(){
    setApiMessage("");
    if(spanElement.current && Visivility(spanElement.current)){
      onAcept();
      return;
    }
    setApiMessage("por favor, lea por completo los Términos y Condiciones de Uso.");
  }

  const handleScroll = (e: any) => {
    if(isRead) return;
    const bottom = e.target.scrollHeight - e.target.scrollTop < e.target.clientHeight + 10;
    if (bottom) { 
      setIsRead(true);
     }

  }

  return (
    <div className={show ? 'modal fade show' : 'modal fade'} style={show ? {display: "block", backgroundColor: "rgba(0,0,0,0.5)"} : {} } aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Términos y condiciones</h5>
            <button onClick={() => onClose()}  type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body text-center" onScroll={(e) => handleScroll(e)}>
            <div dangerouslySetInnerHTML={{__html: body}}></div>
            <span ref={spanElement}></span>
          </div>
          <div className="modal-footer">
            <Button title="Volver"  secondary onPress={onClose} />
            <Button title="Aceptar" disabled={!isRead} onPress={handleClickAcept} />
            {
              apiMessage
              ? <div className="text-danger w-100">{apiMessage}</div>
              : <div className="my-3 w-100"></div>
            }
          </div>
        </div>
      </div>
    </div>
  )
}