export function SkeletonTable() {
  return (
    <div className="">
      <p className="placeholder-glow">
        <span className="btn btn-platform disabled placeholder m-1 col-12"></span>
        <span className="placeholder m-1 col-12"></span>
        <span className="placeholder m-1 col-12"></span>
        <span className="placeholder m-1 col-12"></span>
        <span className="placeholder m-1 col-12"></span>
        <span className="placeholder m-1 col-12"></span>
      </p>
    </div>
  )
}

export function SkeletonAlert() {
  return (
    <div className="alert alert-secondary">
      <div className="placeholder-glow text-center">
        <span className="placeholder m-1 col-12"></span>
        <span className="placeholder m-1 col-4"></span>
        <span className="placeholder m-1 col-7"></span>
        <span className="placeholder m-1 col-4"></span>
        <p className="col-12">
          <span className="btn btn-platform disabled placeholder px-5"></span>
        </p>
      </div>
    </div>
  )
}