import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import store from './store';
import "./assets/styles/bootstrap.min.css";
import "./assets/styles/main.css";

import Routes from './routes';
import ErrorBoundary from './pages/ErrorBoundary';

function App() {
  useEffect(() => {
    require(`./assets/styles/${process.env.REACT_APP_PLATFORM?.toLocaleLowerCase()}.css`);
  }, []);
 
  return (
    <ErrorBoundary>
      <Provider store={store}>
        {process.env.NODE_ENV === "development" && process.env.REACT_APP_PLATFORM === "Maxdream1" ? <span className="fixed-top badge bg-primary text-white">DEV MODE: {process.env.REACT_APP_PLATFORM}</span> : <></>}
        {process.env.NODE_ENV === "development" && process.env.REACT_APP_PLATFORM === "Laketravel1" ? <span className="fixed-top badge bg-danger text-white">DEV MODE: {process.env.REACT_APP_PLATFORM}</span> : <></>}
        <Routes />
      </Provider>
    </ErrorBoundary>
  );
}

export default App;