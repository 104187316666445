import Button from './Button';

type ModalProps = {
  children: React.ReactNode,
  title: string,
  onClose: Function,
  show: boolean,
  btnRechazar?: boolean,
  onRechazar?: Function,
  btnAceptar?: string,
  onAceptar?: Function,
  hideClose?: boolean,
  onLoadAceptar?: boolean,
}

export default function Modal({ children, title, onClose, hideClose = false, show, btnRechazar = false, onRechazar = () => { }, btnAceptar = "", onAceptar = () => { }, onLoadAceptar = false }: ModalProps) {
  return (
    <div title="modal" className="modal fade show" style={{ backgroundColor: '#000000aa', display: show ? 'block' : 'none' }}>
      <div className="modal-dialog modal modal-dialog-scrollable modal-dialog-centered">
        <div className="modal-content" style={{ boxShadow: '0 .125rem .25rem rgba(0,0,0,.075)' }}>
          <div className="modal-header">
            <h5 className="modal-title">{title}</h5>
            {
              hideClose
                ? <></>
                : <button title="icon-btn-close" type="button" className="btn-close" onClick={() => onClose()}></button>
            }
          </div>
          <div className="modal-body" >
            {children}
          </div>
          <div className="modal-footer">
            {
              hideClose
                ? <></>
                : <button type="button" className="btn btn-secondary" onClick={() => onClose()}>Cerrar</button>
            }

            {
              btnRechazar
                ? <span onClick={() => onRechazar()}>rechazar</span>
                : <></>
            }
            {
              btnAceptar
                ? <Button title={btnAceptar} onPress={() => onAceptar()} onLoad={onLoadAceptar} />
                : <></>
            }
          </div>
        </div>
      </div>
    </div>
  )
}