import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Api from "../../utils/api";
import { setRoute } from "../../store/actions/routeActions";
import { useDispatch } from 'react-redux';
import { SkeletonTable } from "../../components/Skeleton";

interface ISalida {
  AEREO_AEROPUERTO: string;
  AEREO_COMPANIA: string;
  AEREO_RESERVA: string;
  AEREO_VUELO: string;
  DONDE: string;
  ESTADO: string;
  FECHA: string;
  HORA: string;
  N_ORDEN: string;
  TRAMO: string;
}

export default function LugarYHorarioDeSalida() {
  const [salida, setSalida] = useState<Array<ISalida>>([]);
  const [regreso, setRegreso] = useState<Array<ISalida>>([]);
  const [onLoad, setOnLoad] = useState(true);

  const { id = "-1" } = useParams();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setRoute({
      section: 'pasajero',
      sub: 'lugar-horario-salida',
      cp: id,
    }));
  }, [dispatch, id]);

  useEffect(() => {
    Api("consumir").post({
      servicio: "paxSalida",
      param: {
        pco_pasajero: id,
      },
    }).then((res) => {
      setOnLoad(false);
      setSalida(res.PCUR_SALIDA.sort((a: ISalida, b: ISalida) => a.N_ORDEN > b.N_ORDEN ? 1 : -1));
      setRegreso(res.PCUR_REGRESO.sort((a: ISalida, b: ISalida) => a.N_ORDEN > b.N_ORDEN ? 1 : -1));
    });
  }, [id]);

  return (
    <div className="container-data table-responsive">
      {
        onLoad
          ? <>
            <SkeletonTable />
            <SkeletonTable />
          </>
          : <>
            <table className="table table-striped">
              <thead>
                <tr className="th-platform">
                  <th>Salida</th>
                  <th>Ubicacion</th>
                  <th>Fecha</th>
                  <th>Horario</th>
                  <th>Estado</th>
                </tr>
              </thead>
              <tbody>
                {
                  salida.map(etapa => (
                    <tr key={"s" + etapa.N_ORDEN}>
                      <td>{etapa.TRAMO}</td>
                      <td>{etapa.DONDE}</td>
                      <td>{etapa.FECHA}</td>
                      <td>{etapa.HORA}</td>
                      <td>{etapa.ESTADO}</td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
            <table className="table table-striped mt-5">
              <thead>
                <tr className="th-platform">
                  <th>Salida</th>
                  <th>Ubicacion</th>
                  <th>Fecha</th>
                  <th>Horario</th>
                  <th>Estado</th>
                </tr>
              </thead>
              <tbody>
                {
                  regreso.map(etapa => (
                    <tr key={"r" + etapa.N_ORDEN}>
                      <td>{etapa.TRAMO}</td>
                      <td>{etapa.DONDE}</td>
                      <td>{etapa.FECHA}</td>
                      <td>{etapa.HORA}</td>
                      <td>{etapa.ESTADO}</td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </>
      }
    </div>)
}