import React, { useRef, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, Link } from "react-router-dom";
import { RootState } from '../../store/reducers';
import Api from '../../utils/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronCircleLeft, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import Button from '../../components/Button';
import Modal from '../../components/Modal';

export default function Recuperar() {
  const [messageApi, setMessageApi] = useState("");
  const [mail, setMail] = useState("");
  const [onLoad, setOnLoad] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [bodyModal, setModalBody] = useState("");

  const form = useRef<HTMLFormElement>(null);
  // navigate
  const navigate = useNavigate();

  // state
  const state = useSelector((state: RootState) => state);

  function handleLogin() {
    setMessageApi("");
    setOnLoad(true);
    Api("usuarioRecuperarClave").post({
      mail,
    })
      .then(res => {
        if (res.mensaje.tipo === "E") {
          setMessageApi(res.mensaje.descripcion);
          return;
        }
        setShowModal(true);
        setModalBody(res.mensaje.descripcion);
      })
      .finally(() => {
        setOnLoad(false);
      });
  }

  useEffect(() => {
    document.querySelector("body")?.classList.add("bg-login");
    if (!state.auth.jwt) {
      return;
    }
    document.querySelector("body")?.classList.remove("bg-login");
    navigate("/usuario/tus-datos");
  }, [state, navigate]);

  return (
    <div className="bg-login h-100 pb-sm-5 pb-1 mt-sm-3 pt-1 px-3">
      <Link to="/iniciar-sesion" className="text-white fs-3 text-decoration-none"><FontAwesomeIcon icon={faChevronCircleLeft} className="me-2" />volver</Link>
      <h1 className="h1-login-title d-flex justify-content-center mx-auto mb-5">{process.env.REACT_APP_PLATFORM} - Sucursal Virtual</h1>
      <div className="mx-auto my-auto bg-light rounded shadow-lg container-recuperar-pwd">
        <div className="row">
          <div className="col-12 bg-white rounded-2 p-md-5 p-2 py-3">
            <h1 className="color-platform text-center mb-5">Recuperar contraseña</h1>
            <form ref={form}>
              <div>
                <span className="fw-bold">Correo electrónico</span>
                <input className="form-control" type="email" name="mail" value={mail} onChange={(e) => setMail(e.target.value)} placeholder="ejemplo@correo.com" required />
              </div>
              <div className="text-center mt-4">
                <Button title="Recuperar contraseña" onLoad={onLoad} onPress={() => handleLogin()} />
              </div>
              <p className="text-danger text-center mt-3" dangerouslySetInnerHTML={{__html: messageApi}}></p>
            </form>
            <div className="d-flex justify-content-center" id="opts-login">
              <ul className="login-more p-t-20 mt-3">
                <li>
                  <span className="text-muted">
                    Todavía no tienes una cuenta?
                  </span>
                  <Link className="a-link ps-1" to="/registro">Regístrate</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Modal hideClose={true} title="Contraseña recuperada con éxito" show={showModal} onClose={() => setShowModal(false)}>
        <div className="text-center">
          <FontAwesomeIcon icon={faCheckCircle} className="text-success" style={{fontSize: 100}} />
          <p dangerouslySetInnerHTML={{__html: bodyModal}}></p>
          <Link to="/iniciar-sesion"><Button title="Iniciar Sesión" onPress={() => {}} /></Link>
        </div>
      </Modal>
    </div>)
}