import { Action } from "redux";
import { SET_OBJ_SOLICITUDES } from "../types";
import { emptyResponseSolicitud } from "../../interfaces/ISolicitud";
import IResponseSolicitud from "../../interfaces/IResponseSolicitud";

let initialState: IResponseSolicitud = emptyResponseSolicitud;

class SolicitudAction implements Action {
  type: string;
  payload: IResponseSolicitud;
  constructor(type: string, payload: IResponseSolicitud) {
    this.type = type;
    this.payload = payload;
  }
}

export default function objSolicitudReducer(state: IResponseSolicitud = initialState, action: SolicitudAction) {
  switch (action.type) {
    case SET_OBJ_SOLICITUDES:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}