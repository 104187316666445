import { Outlet, Link } from "react-router-dom";
import { RootState } from "../store/reducers";
import { useSelector } from 'react-redux';

export default function PanelUsuario(){
  const route = useSelector((state: RootState) => state.route);
  return (
  <div>
  <h3 className="color-platform mb-5">Tu usuario</h3>
    <nav className="subtitle-dashboard d-none d-xl-block">
      <Link className={route.sub === "tus-datos" ? 'active' : ''} to="/usuario/tus-datos">tus datos</Link>
      <Link className={route.sub === "cambiar-clave" ? 'active' : ''} to="/usuario/cambiar-clave">cambiar contraseña</Link>
    </nav>
    <Outlet />
  </div>)
}