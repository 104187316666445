import { Action } from "redux";
import { SET_PASAJEROS } from "../types";
import IPasajeros from "../../interfaces/IPasajeros";

let initialState:IPasajeros = {
  pasajeros: [],
};

let pasajerosLocalStorage: string = localStorage.getItem("pasajeros") || ""; 
if(pasajerosLocalStorage){
  initialState = JSON.parse(pasajerosLocalStorage);
}

class PasajerosAction implements Action {
  type: string;
  payload: IPasajeros;
  constructor(type: string, payload: IPasajeros){
    this.type = type;
    this.payload = payload;
  }
}

export default function pasajerosReducer(state: IPasajeros = initialState, action: PasajerosAction){
  switch(action.type){
    case SET_PASAJEROS:
    localStorage.setItem("pasajeros", JSON.stringify(action.payload));
    return {...state, ...action.payload};
    default:
      return state;
  }
}