import { combineReducers } from "redux";
import authReducer from "./authReducer";
import pasajerosReducer from "./pasajerosReducer";
import campoSolicitudReducer from "./campoSolicitudReducer";
import ObjSolicitudReducer from "./ObjSolicitudReducer";
import RouteReducer from "./routeReducer";
import FichaMedicaReducer from './FichaMedicaReducer';

const reducers = combineReducers({
  auth: authReducer,
  pasajeros: pasajerosReducer,
  campoSolicitud: campoSolicitudReducer,
  ObjSolicitud: ObjSolicitudReducer,
  route: RouteReducer,
  fichaMedica: FichaMedicaReducer,
});

export default reducers;
export type RootState = ReturnType<typeof reducers>;