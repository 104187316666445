import IResponseSolicitud from "./IResponseSolicitud";
import ICampoSolicitud from "./ICampoSolicitud";

export default interface ISolicitud {
  campos: ICampoSolicitud,
  response: IResponseSolicitud,
}

export const emptyResponseSolicitud: IResponseSolicitud = {
  SOLICITUD: {
    CLAVE_GRUPO: "",
    VIAJE_PARA: "",
    VIAJE_ANIO: "",
    DESTINO: "",
    ESTABLECIMIENTO: "",
    ANIO: "",
    DIVISION: "",
    TURNO: "",
    APELLIDO: "",
    NOMBRE: "",
    TIPO_DOCUMENTO: "",
    NRO_DOCUMENTO: "",
    FECHA_NACIMIENTO: "",
    F_VEN_DOCUMENTO: "",
    C_NACIONALIDAD: "",
    SEXO: "",
    REMERA: "",
    PANTALON: "",
    CAMPERA: "",
    CALZADO: "",
    PAIS: "",
    CALLE: "",
    ALTURA: "",
    PISO: "",
    DPTO: "",
    LOCALIDAD: "",
    PROVINCIA: "",
    CODIGO_POSTAL: "",
    TELEFONO: "",
    CELULAR: "",
    PORQUE: "",
    FAMILIARES: [],
  }
}

export const emptyCamposSolicitud: ICampoSolicitud = {
  colegios: [],
  destinos: [],
  documentos: [],
  parentescos: [],
  planes: [],
  pqnoselegiste: [],
  provincias: [],
  sexos: [],
  solicitudPara: [],
  talles: [],
  turnos: [],
  tiposViajes: [],
  tyc: [],
  steep: 0,
}