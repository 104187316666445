import React, { useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SparkMD5 from "spark-md5";
import { useNavigate, Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronCircleLeft } from '@fortawesome/free-solid-svg-icons';
import config from '../../config';

import { login } from "../../store/actions/authActions";
import { RootState } from '../../store/reducers';
import formToObj from '../../utils/formToObj';
import Api from '../../utils/api';
import IAuth from '../../interfaces/IAuth';
import Button from '../../components/Button';

export default function Login() {
  const [messageApi, setMessageApi] = useState("");
  const [isFetching, setIsFetching] = useState(false);
  const [loginPhoto, setLoginPhoto] = useState();

  const form = useRef<HTMLFormElement>(null);
  // navigate
  const navigate = useNavigate();

  // state
  const state = useSelector((state: RootState) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    setLoginPhoto(require(`../../assets/images/${process.env.REACT_APP_PLATFORM?.toLocaleLowerCase()}/login.jpeg`))
  }, []);

  function handleLogin() {
    if(!form.current){
      return;
    }

    const body = formToObj(form.current);

    setMessageApi("");
    setIsFetching(true);
    Api("login").post({...body, clave: SparkMD5.hash(String(body.clave).toUpperCase() )})
      .then(res => {
        setIsFetching(false);
        if (res.mensaje.tipo === "E") {
          setMessageApi(res.mensaje.descripcion);
          return;
        }

        const auth: IAuth = {
          nombre: res.data.nombre,
          apellido: res.data.apellido,
          empresa: res.data.empresa,
          idUsuario: res.data.idUusario,
          jwt: res.jwt,
          cambiarClave: res.cambiarClave === "S",
          email: body.usuario.toString(),
          sesionTime: Date.now() + config.SESION_TIME,
        };
        dispatch(login(auth));

        if(res.cambiarClave === "S") {
          navigate("/cambiar-clave");
          return;
        }
      });
  }

  useEffect(() => {
    document.querySelector("body")?.classList.add("bg-login");
    if (!state.auth.jwt) {
      return;
    }
    document.querySelector("body")?.classList.remove("bg-login");
    if(state.auth.cambiarClave){
      navigate("/usuario/cambiar-clave");
      return;
    }
    navigate("/usuario/tus-datos");
  }, [state, navigate]);

  return (
    <div className="bg-login h-100 pb-sm-5 pb-1 mt-sm-3 pt-1 px-3">
        <a href="https://www.maxdream.tur.ar/" className="text-white fs-3 text-decoration-none"><FontAwesomeIcon icon={faChevronCircleLeft} className="me-2" />volver</a>
      <h1 className="h1-login-title d-flex justify-content-center mx-auto mb-sm-5 pb-1">{process.env.REACT_APP_PLATFORM} - Sucursal Virtual</h1>
      <div className="container my-auto bg-light rounded shadow-lg">
        <div className="">
          <div className="row">
            <div className="col-md-5 col-12 bg-login-image" style={{ backgroundImage: `url(${loginPhoto})` }}></div>
            <div className="col-md-7 col-12 bg-white rounded-2 p-sm-5 p-2">
              <h1 className="color-platform text-center mb-sm-5 mb-2">Iniciar sesión</h1>
              <form ref={form}>
                <div>
                  <span className="fw-bold">Correo electrónico - DNI - Código de pasajero</span>
                  <input className="form-control" type="email" name="usuario"  placeholder="ejemplo@correo.com" required />
                </div>
                <div className="mt-4">
                  <span className="fw-bold">Contraseña</span>
                  <input className="form-control" type="password" name="clave" onKeyDown={(e) => e.key === "Enter" ? handleLogin() : null} placeholder="*******" required />
                </div>
                <div className="text-center mt-4">
                  <Button title="iniciar sesion" onLoad={isFetching} onPress={() => handleLogin()} />
                  <p className="ml-0 mt-3"><Link to={'/estadias'} className="a-link ps-1" >Valida tu estadïa</Link></p>
                </div>
                <p className="text-danger text-center mt-1">{messageApi}</p>
              </form>
              <div className="d-flex justify-content-center" id="opts-login">
                <ul className="login-more p-t-20">
                  <li className="m-b-8">
                    <span className="text-muted">
                      Ha olvidado su contraseña?
                    </span>
                    <Link className="a-link ps-1" to="/recuperar">Recuperar contraseña</Link>
                  </li>
                  <li>
                    <span className="text-muted">
                      Todavía no tienes una cuenta?
                    </span>
                    <Link className="a-link ps-1" to="/registro">Regístrate</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>)
}