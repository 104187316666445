import React, { useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store/reducers";
import { setRoute } from "../../store/actions/routeActions";

import { resetSteep } from "../../store/actions/solicitudActions";
import CodigoGrupo from "../../components/solicitud/CodigoGrupo";
import DatosDelViaje from "../../components/solicitud/DatosDelViaje";
import DatosDelPasajero from "../../components/solicitud/DatosDelPasajero";
import DatosPadres from '../../components/solicitud/DatosPadres';
import DatosHermanos from '../../components/solicitud/DatosHermanos';
import OtrosDatos from "../../components/solicitud/OtrosDatos";

export default function SolicitudNueva() {
  const solicitud = useSelector((state: RootState) => state.campoSolicitud);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetSteep());
    dispatch(setRoute({
      section: 'solicitudes',
      sub: 'nueva',
      cp: '',
    }));
  }, [dispatch]);
  return (
    <div className="container-data">
      <div className="container mt-3 pt-4 pb-1 d-none d-lg-block">
        <ul className="list-unstyled multi-steps">
          <li className={solicitud.steep === 0 ? "text-step is-active" : "text-step"}>Código de Grupo</li>
          <li className={solicitud.steep === 1 ? "text-step is-active" : "text-step"}>Datos del Viaje</li>
          <li className={solicitud.steep === 2 ? "text-step is-active" : "text-step"}>Datos del Pasajero que viaja</li>
          <li className={solicitud.steep === 3 ? "text-step is-active" : "text-step"}>Datos de Padre, Madre o Tutor</li>
          <li className={solicitud.steep === 4 ? "text-step is-active" : "text-step"}>Hermanos/as</li>
          <li className={solicitud.steep === 5 ? "text-step is-active" : "text-step"}>Otros Datos</li>
        </ul>
      </div>
      <div className={solicitud.steep === 0 ? '' : 'd-none'}>
        <CodigoGrupo />
      </div>
      {solicitud.steep > 0 &&
        <>
          <div className={solicitud.steep === 1 ? '' : 'd-none'}><DatosDelViaje /></div>
          <div className={solicitud.steep === 2 ? '' : 'd-none'}><DatosDelPasajero /></div>
          <div className={solicitud.steep === 3 ? '' : 'd-none'}><DatosPadres /></div>
          <div className={solicitud.steep === 4 ? '' : 'd-none'}><DatosHermanos /></div>
          <div className={solicitud.steep === 5 ? '' : 'd-none'}><OtrosDatos /></div>
        </>
      }
    </div>
  );
}