import React, { useRef, useState } from 'react';
import Api from '../../utils/api';
import Button from '../../components/Button';
import Modal from '../../components/Modal';
import { Link } from 'react-router-dom';

export default function Recuperar() {
  const [claveActual, setClaveActual] = useState("");
  const [claveNueva, setClaveNueva] = useState("");
  const [confirmarClaveNueva, setConfirmarClaveNueva] = useState("");
  const [messageApi, setMessageApi] = useState("");
  const [isFetching, setIsFetching] = useState(false);
  const [showModal, SetShowModal] = useState(false);

  const form = useRef<HTMLFormElement>(null);

  function handleChangePwd() {
    setMessageApi("");
    if (!form.current?.checkValidity()) {
      return;
    }

    if (claveNueva !== confirmarClaveNueva) {
      setMessageApi("la contraseñas no coinciden");
      return;
    }
    setIsFetching(true);
    Api("consumir").post({
      servicio: "cambioClave",
      param: {
        pd_clave_old: claveActual,
        pd_clave_new: claveNueva
      },
    }).then(res => {
      if (res.mensaje.tipo === "E") {
        setMessageApi(res.mensaje.descripcion);
        return;
      }
      SetShowModal(true);
    })
    .finally(() => {
      setIsFetching(false);
    });

  }

  return (
    <div className="bg-login pb-5 px-3">
      <h1 className="h1-login-title d-flex justify-content-center mx-auto py-5">{process.env.REACT_APP_PLATFORM} - Sucursal Virtual</h1>
      <div className="mx-auto my-auto bg-light rounded shadow-lg container-recuperar-pwd">
        <div className="row">
          <div className="col-12 bg-white rounded-2 p-md-5 p-2">
            <h1 className="color-platform text-center mb-5">Cambiar contraseña</h1>
            <form ref={form}>
              <div className="my-3">
                <span>Contraseña actual</span>
                <input className="form-control" type="password" name="clave-actual" onChange={(e) => setClaveActual(e.target.value)} placeholder="****" required />
              </div>
              <div className="my-3">
                <span>Contraseña nueva</span>
                <input className="form-control" type="password" name="clave-nueva" placeholder="*******" onChange={(e) => setClaveNueva(e.target.value)} required />
              </div>
              <div className="my-3">
                <span>Confirme contraseña nueva</span>
                <input className="form-control" type="password" name="confirmar-clave-nueva" placeholder="*******" onChange={(e) => setConfirmarClaveNueva(e.target.value)} required />
              </div>
              <div className="text-center mt-3">
                <Button title="cambiar contraseña" onLoad={isFetching} onPress={() => handleChangePwd()} />
              </div>
              <p className="text-danger mt-3 text-center">{messageApi}</p>
            </form>
          </div>
        </div>
      </div>
      <Modal title="Contraseña cambiada con éxito" show={showModal} onClose={() => {}} hideClose={true}>
        <div className="text-center">
          <p>hola</p>
          <Link to="/"><Button title="Iniciar Sesión" onPress={() => { }} /></Link>
        </div>
      </Modal>
    </div>)
}