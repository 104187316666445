import { Action } from "redux";
import { SET_INPUT_SOLICITUDES, BACK_STEEP_SOLICITUDES, NEXT_STEEP_SOLICITUDES, RESET_STEEP_SOLICITUDES } from "../types";
import ICampoSolicitud from "../../interfaces/ICampoSolicitud";
import { emptyCamposSolicitud } from "../../interfaces/ISolicitud";

let initialState: ICampoSolicitud = emptyCamposSolicitud;

class SolicitudAction implements Action {
  type: string;
  payload: ICampoSolicitud;
  constructor(type: string, payload: ICampoSolicitud) {
    this.type = type;
    this.payload = payload;
  }
}

export default function campoSolicitudReducer(state: ICampoSolicitud = initialState, action: SolicitudAction) {
  switch (action.type) {
    case SET_INPUT_SOLICITUDES:
      return { ...state, ...action.payload };
    case BACK_STEEP_SOLICITUDES:
      return { ...state, steep: state.steep - 1 };
    case NEXT_STEEP_SOLICITUDES:
      return { ...state, steep: state.steep + 1 };
    case RESET_STEEP_SOLICITUDES:
      return { ...state, steep: 0 };
    default:
      return state;
  }
}