import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Api from "../../utils/api";
import { useDispatch } from 'react-redux';
import { setRoute } from "../../store/actions/routeActions";
import { SkeletonTable } from "../../components/Skeleton";

interface IDatoPasajero {
  D_GRUPO: string;
  D_ID: string;
  D_TITULO: string;
  D_VALOR: string;
  N_ID: string;
  N_ORDEN: number;
}

interface IResponse {
  PD_ERROR: string;
  PM_ERROR: string;
  PREF_DATOS: IDatoPasajero[];
  mensaje: any;
}

export default function DatosDelPasajero() {
  const [onLoad, setOnLoad] = useState(true);
  const [datosPasajero, setDatosPasajero] = useState<Array<IDatoPasajero>>([])

  const { id = "-1" } = useParams();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setRoute({
      section: 'pasajero',
      sub: 'datos-del-pasajero',
      cp: id,
    }));
  }, [dispatch, id]);

  useEffect(() => {
    setOnLoad(true);
    setDatosPasajero([]);
    Api("consumir").post({
      servicio: "paxDatos",
      param: {
        pco_pasajero: id,
      },
    }).then((res: IResponse) => {
      setOnLoad(false);
      setDatosPasajero(res.PREF_DATOS.sort((a, b) => Number(a.N_ORDEN) > Number(b.N_ORDEN) ? 1 : -1));
    });
  }, [id]);

  return (<div className="container-data">
    <div className="table-responsive container">
      {
        onLoad
          ? <SkeletonTable />
          : <>
          <table className="table table-striped responsive-datatable d-none d-md-table">
            <thead>
              <tr className="th-platform"><th colSpan={2}>Datos del pasajero:</th></tr>
            </thead>
            <tbody>
              {datosPasajero.map((dato: IDatoPasajero) => (
                <tr key={dato.D_GRUPO + dato.N_ORDEN}>
                  <th>{dato.D_TITULO}</th>
                  <td>{dato.D_VALOR}</td>
                </tr>
              ))
              }
            </tbody>
          </table>
          <div className="d-md-none">
          {datosPasajero.map((dato: IDatoPasajero) => (
              <div key={'m'+dato.D_GRUPO + dato.N_ORDEN}>
                <p className="m-0 p-2 fw-bold bg-light-secondary">{dato.D_TITULO}</p>
                <p className="m-0 p-2 bg-wihte">{dato.D_VALOR}</p>
              </div>
            ))
          }
          </div>
          </>
      }
    </div>
  </div>)
}