export const LOG_IN = "LOG_IN";
export const LOG_OUT = "LOG_OUT";
export const RELOAD_TIME = "RELOAD_TIME";

export const SET_PASAJEROS = "SET_PASAJEROS";

export const SET_INPUT_SOLICITUDES = "SET_INPUT_SOLICITUDES";
export const NEXT_STEEP_SOLICITUDES = "NEXT_STEEP_SOLICITUDES";
export const BACK_STEEP_SOLICITUDES = "BACK_STEEP_SOLICITUDES";
export const RESET_STEEP_SOLICITUDES = "RESET_STEEP_SOLICITUDES";

export const SET_OBJ_SOLICITUDES = "SET_OBJ_SOLICITUDES";

export const SET_ROUTE = "SET_ROUTE";

export const SET_INPUT_FICHA_MEDICA = "SET_INPUT_FICHA_MEDICA";
export const NEXT_STEEP_FICHA_MEDICA = "NEXT_STEEP_FICHA_MEDICA";
export const BACK_STEEP_FICHA_MEDICA = "BACK_STEEP_FICHA_MEDICA";