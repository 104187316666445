import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import Button from '../../components/Button';
import { SkeletonTable } from '../../components/Skeleton';
import { setRoute } from '../../store/actions/routeActions';

interface ITurno {
  D_TITULO: string,
  D_VALOR: string;
}

export default function VerTurnos() {
  const [agendarTurno, setAgendarTurno] = useState<boolean>(false);
  const [, setBorrarTurno] = useState<boolean>(false);
  const [messsage, setMessage] = useState<string>("");
  const [turnos, setTurnos] = useState<Array<ITurno>|null>(null);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setRoute({
      section: 'turno',
      sub: '',
      cp: '',
    }));
  }, [dispatch]);

  useEffect(() => {
    const res = {"P_MENSAJE":`<div style="padding: 10px; background-color: #F0F3F4; color: black;"><p style="text-align: center;"><strong>COVID 19</strong></p><p style="text-align: center;"><strong>Seguimos cuid\u00e1ndonos entre todos</strong></p><p>&nbsp;Agradecemos el uso del m\u00f3dulo de Autogesti\u00f3n de turnos online para no congestionar el servicio telef\u00f3nico. El ingreso a las oficinas se permitir\u00e1 exclusivamente a personas con turno, que ser\u00e1 verificado por el personal de seguridad del edificio.</p><p>&nbsp;<strong>Al presentarse en nuestras oficinas con su turno, para ingresar:</strong></p><p><strong>Solo una persona por pasajero y turno, sin acompa\u00f1antes, sin excepciones.</strong></p><p>*)Uso obligatorio de barbijo o tapabocas.<br/>*)Se le tomar\u00e1 la temperatura con term\u00f3metro infrarrojo. No podr\u00e1 ingresar ninguna persona con m\u00e1s de 37\u00b0.<br/>*)Se le solicitar\u00e1 la sanitizaci\u00f3n de manos con alcohol al 70% al ingresar.<br/>*)Le recomendamos traer una birome para su uso personal.</p></div><br/>Actualmente no tiene registrado ning\u00fan turno para una atenci\u00f3n personalizada.<br/>Si necesita hacer un tr\u00e1mite que no se pueda realizar desde la SUCURSAL VIRTUAL y necesita concurrir a alguna oficina, por favor registre un turno de atenci\u00f3n.`,"P_BOTON_BORRAR":"N","P_BOTON_AGENDAR":"S","PM_ERROR":"M","PD_ERROR":null,"PREF_TURNO":[],"mensaje":{"tipo":"M","codigo":"0","descripcion":null}}
    if(res.mensaje.tipo !== "M"){
      return;
    }

    setMessage(res.P_MENSAJE);
    setBorrarTurno(res.P_BOTON_BORRAR === "S");
    setAgendarTurno(res.P_BOTON_AGENDAR === "S");
    setTurnos(res.PREF_TURNO);


  }, [])
  return (
    <div>
      <h3 className="color-platform mb-5">Ver turnos</h3>
      <div className="container-data">
          <div dangerouslySetInnerHTML={{__html: messsage}}></div>
          <div className="text-center mt-3">
          {
            agendarTurno ? <Link to="/turno/agendar"><Button title="Agendar un Turno" onPress={() => {}} /></Link> : <></>
          }
          </div>
      </div>
      <div className="container-data">
        <div className="table-resposnive">
          {
            turnos
            ? <table className="table table-striped">
            <thead>
              <tr className="th-platform">
                <th colSpan={2}>Datos del turno</th>
              </tr>
            </thead>
            <tbody>
              {
                turnos.map(row => <tr key={row.D_TITULO}>
                  <td>{row.D_TITULO}</td>
                  <td>{row.D_VALOR}</td>
                </tr>)
              }
              {
                turnos.length
                ? <></>
                : <tr><td className="text-center" colSpan={2}>No hay turnos agendados</td></tr>
              }
            </tbody>
          </table>
            : <SkeletonTable />
          }
        </div>
      </div>
    </div>
  )
}