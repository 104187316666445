import { Outlet, Link } from "react-router-dom";
import { RootState } from "../store/reducers";
import { useSelector } from 'react-redux';

export default function PanelSolicitud(){
  const route = useSelector((state: RootState) => state.route);
  return (
  <div>
    <h3 className="color-platform mb-5">Tus solicitudes</h3>
    <nav className="subtitle-dashboard d-none d-xl-block">
      <Link className={route.sub === "tus-solicitudes" ? 'active' : ''} to="/solicitudes/tus-solicitudes">Solicitudes ingresadas</Link>
      <Link className={route.sub === "nueva" ? 'active' : ''} to="/solicitudes/nueva">Solicitud nueva</Link>
    </nav>
    <Outlet />
  </div>)
}